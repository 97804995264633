import React from "react"
import { IoMdClose } from "react-icons/io"
const Dialog = ({ isOpen, handleClose, children, small }) => {
  return (
    <div
      onClick={handleClose}
      className={`
    fixed inset-0 flex justify-center items-center transition-colors 0
    z-[100]
    ${isOpen ? "visible bg-black/20" : "invisible"}
  `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
      bg-white rounded-xl shadow px-6 transition-all ${
        small ? "w-[25%] h-[25%]" : "w-[85%] h-[85%]"
      }
      ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
    `}
      >
        <IoMdClose
          size={30}
          onClick={handleClose}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 cursor-pointer "
        />
        {children}
      </div>
    </div>
  )
}

export default Dialog
