import React, { useState } from "react";
import Dialog from "./Dialog";
import { BackDropLoader } from "../loader";
import ReactDatePicker from "react-datepicker";

const UpdateArrivalDate = ({
  open,
  handleClose,
  onSubmitForm,
  isLoading,
  dateUpdate,
  setDateUpdate,
}) => {
  
  return (
    <Dialog small={true} isOpen={open} handleClose={handleClose}>
      {isLoading && <BackDropLoader />}
      <div className="flex h-full w-full flex-col justify-center gap-5">
        <ReactDatePicker
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[3px] block w-full p-[0.52em] font-semibold text-[1rem] focus:outline-gray-800"
          selected={dateUpdate}
          onChange={(date) => setDateUpdate(date)}
        />
        <div className=" flex flex-col gap-1">
          <button
            onClick={onSubmitForm}
            className="font-semibold bg-blue-400 hover:bg-blue-500 text-white py-1 rounded-md"
          >
            Update Arrival Date
          </button>
          <button
            className="font-semibold bg-gray-200 py-1 rounded-md hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateArrivalDate;
