import React, { useEffect, useState } from "react"
import { Model } from "../../../../../formModel/package"
import { Checkbox, InputFieldWithLabel } from "../../../../common/formElements"
import { useFormikContext } from "formik"

const PackageCost = ({ type }) => {
  const { values, setFieldValue } = useFormikContext()

  const {
    formField: { cost, wantToUseCalculator, customCostPerPound }
  } = Model
  useEffect(() => {
    let shippingCost
    if (values.weight) {
      if (values.wantToUseCalculator) {
        if (type === "express") {
          if (Number(values.weight) > 0 && Number(values.weight) <= 40) {
            shippingCost = 120
          } else {
            shippingCost = 140
          }
          setFieldValue(cost.name, shippingCost)
        } else {
          shippingCost = (Number(values.weight) * 2.1).toFixed(5)
          setFieldValue(cost.name, shippingCost)
        }
      } else {
        if (values.customCostPerPound) {
          shippingCost = (
            Number(values.weight) * Number(values.customCostPerPound)
          ).toFixed(5)
          setFieldValue(cost.name, shippingCost)
        } else {
          setFieldValue(cost.name, "")
        }
      }
    } else {
      setFieldValue(cost.name, "")
    }
  }, [
    cost.name,
    setFieldValue,
    type,
    values.customCostPerPound,
    values.height,
    values.length,
    values.type,
    values.wantToUseCalculator,
    values.weight,
    values.width
  ])
  return (
    <>
      <div className="flex flex-row gap-3">
        <Checkbox
          name={wantToUseCalculator.name}
          fieldlabel={`${wantToUseCalculator.label} (${type})`}
          desc={`${wantToUseCalculator.desc} (${type})`}
        />
      </div>
      <div className="flex flex-row gap-3">
        {!values.wantToUseCalculator && (
          <InputFieldWithLabel
            fieldlabel={customCostPerPound.label}
            placeholder={customCostPerPound.label}
            name={customCostPerPound.name}
            // disabled={true}
            priceField={true}
            onChange={(event) =>
              setFieldValue(
                customCostPerPound.name,
                event.target.value.replace(/[^0-9.]/g, "")
              )
            }
          />
        )}

        <InputFieldWithLabel
          fieldlabel={cost.label}
          placeholder={cost.label}
          name={cost.name}
          disabled={true}
          priceField={true}
        />
      </div>
    </>
  )
}

export default PackageCost
