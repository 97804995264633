import React from "react"
import { useField } from "formik"
import ErrorMessage from "./ErrorMessage"
import Label from "./Label"

const TextareaWithLabel = ({ fieldlabel, ...props }) => {
  const [field, meta] = useField(props)
  const { error, touched } = meta

  return (
    <div className="flex flex-col items-start gap-[0.1em] w-full">
      <Label label={fieldlabel} />
      <div className="flex flex-col w-full relative">
        <textarea
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[3px] block w-full px-[0.52em] py-[0.65em] font-semibold text-[1rem] focus:outline-gray-800 resize-none"
          {...field}
          {...props}
        />
        <ErrorMessage error={error} touched={touched} />
      </div>
    </div>
  )
}

export default TextareaWithLabel
