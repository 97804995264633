import React from "react"
import { menuData } from "./config"
import { useLocation } from "react-router-dom"
import { useSideMenuContext } from "../../../context/SideMenuContext"

const MenuList = ({ navigateToPage }) => {
  const location = useLocation()
  const { isMenuOpen } = useSideMenuContext()

  const onMenuClick = (path) => {
    navigateToPage(path)
  }
  // console.log({
  //   path: location.pathname
  //     .split("/")
  //     .filter((item) => item !== "")
  //     .slice(0, 2)
  //     .join("/")
  // })
  return (
    // side menu container
    <div
      className={`overflow-x-hidden mt-[1.5em] flex flex-col ${
        isMenuOpen ? "" : "gap-2"
      }`}
    >
      {menuData.map((item, index) => {
        // list item container
        return (
          <div
            key={index}
            className={`flex  rounded-lg border-b-2 items-center border-gray-200  hover:bg-[#87b1f0] cursor-pointer [&_.listIcon]:opacity-10 [&_.listIcon]:hover:opacity-100 ${
              isMenuOpen
                ? "mx-3 my-[0.2em]  flex-row gap-3 py-3 ps-7"
                : "mx-[0.4em] flex-col gap-1 py-3 px-1 "
            } ${
              location.pathname === item.to ||
              location.pathname
                .split("/")
                .filter((item) => item !== "")
                .slice(0, 2)
                .join("/") ===
                item.to
                  .split("/")
                  .filter((item) => item !== "")
                  .slice(0, 2)
                  .join("/")
                ? "bg-[#bbd2f7] [&_.listIcon]:opacity-100"
                : ""
            }`}
            onClick={() => onMenuClick(item.to)}
          >
            <div
              className={`${isMenuOpen ? "" : "text-[0.94rem]"} ${
                location.pathname === item.to ? "" : ""
              } `}
            >
              {item.icon}
            </div>
            <div
              className={`font-semibold  ${
                isMenuOpen ? "text-sm" : "text-[0.8rem] text-center"
              }`}
            >
              {item.title}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MenuList
