export const DateFormat = (inputDate, showTime = false) => {
  const dateObject = new Date(inputDate) // Creating a Date object from the provided date string

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]

  const dayOfWeek = daysOfWeek[dateObject.getDay()] // Extracting the day of the week
  const month = monthsOfYear[dateObject.getMonth()] // Extracting the month
  const dayOfMonth = dateObject.getDate() // Extracting the day of the month
  const year = dateObject.getFullYear() // Extracting the year
  const hours = dateObject.getHours() // Extracting the hours
  const minutes = dateObject.getMinutes() // Extracting the minutes
  const seconds = dateObject.getSeconds() // Extracting the seconds

  // Constructing the desired date format
  if (showTime) {
    return `${month} ${dayOfMonth} ${year} - ${hours}:${minutes}`
  } else {
    return `${month} ${dayOfMonth} ${year}`
  }
}

export const calculateTotalWeight = (data) => {
  return data?.reduce((total, item) => {
    // Ensure that weight is converted to a number before adding to total
    const weight = parseFloat(item?.weight) || 0
    return total + weight
  }, 0)
}

export const calculateTotalCost = (data) => {
  return data?.reduce((total, item) => {
    // Ensure that weight is converted to a number before adding to total
    const weight = parseFloat(item?.cost) || 0
    return total + weight
  }, 0)
}
