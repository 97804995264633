export const columns = [
  {
    title: "Email",
    styles: "basis-[10%]",
    sorting: true,
    key: "email"
  },
  {
    title: "Last used",
    styles: "basis-[10%]",
    // sorting: true,
    key: "updatedAt",
    date: true
  },
]
