import React, { useEffect, useMemo, useState } from "react";
import {
  TableBody,
  TableFooter,
  TableHeader,
  TableTopHeader,
} from "../../common/table";
import { useButtonActions, useDebounce, useDialogModel } from "../../../hooks";
import useTableData from "../../../hooks/useTableData";
import { packagecolumns as columns } from "./config";
import { useGetPackagesQuery } from "../../../app/services/packageSlice";
import { Dialog, DeleteDialog } from "../../common/Dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUpdateWorkOrderPackageFavMutation } from "../../../app/services/workOrderSlice";
import Filters from "./Filters";

const PackageTable = () => {
  const [selectedRow, setSelectedRow] = useState("");
  const [filters, setFilters] = useState({
    type: null,
    favorite: null,
  });
  console.log({ filters });
  // table container
  const [open, openDialog, closeDialog] = useDialogModel();
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    query,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery,
    sortQuery,
  } = useTableData();

  const {
    currentItem,
    isEditing,
    isDeleting,
    startEditing,
    startDeleting,
    resetActions,
    isAdding,
    startAdding,
    startViewing,
    isViewing,
  } = useButtonActions();

  const { state } = useLocation();
  window.history.replaceState({}, document.title);
  const debouncedSearchTerm = useDebounce(query, 200);

  const {
    isLoading,
    isFetching,
    refetch,
    data,
    isError,
    error,
    isUninitialized,
    currentData,
    status,
  } = useGetPackagesQuery(
    {
      limit: pageSize,
      page: currentPage,
      sort: `${sortQuery.dir === "dsc" ? "-" : ""}${sortQuery.field}`,
      type: filters.type,
      favorite: filters.favorite
        ? filters.favorite === "in-favorites"
          ? true
          : false
        : null,
      debouncedSearchTerm,
      searchField: "title",
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const [toogleFavRequest, favRequestQueryResult] =
    useUpdateWorkOrderPackageFavMutation();

  //   const [deleteRequest, { isLoading: isLoadingDeleteMt }] =
  //     useDeleteWorkOrderMutation()
  //   const [updateBulkRequest, { isLoading: isLoadingUpdateBulkWorkOrder }] =
  //     useUpdateBulkWorkOrderMutation()

  const getFilteredData = useMemo(() => {
    if (isLoading || isFetching) return [];
    if (data?.data?.length > 0) {
      setSelectedRow("");
      return data?.data?.filter((item) => {
        return item;
      });
    } else {
      return [];
    }
  }, [isLoading, isFetching, data?.data]);

  const onRefreshClick = () => {
    refetch();
  };

  const onEditItemClick = (item) => {
    navigate(`/dashboard/work-orders/edit/${item._id}`);
  };
  const onDeleteItemClick = (item) => {
    startDeleting(item);
    openDialog();
  };
  const onViewItemClick = (item) => {
    navigate(`/dashboard/work-orders/view/${item._id}`);
  };

  const handleRowSelect = (rowId) => {
    setSelectedRow(() => {
      const updateSelectedRows = selectedRow === rowId ? "" : rowId;
      return updateSelectedRows;
    });
  };

  const onToogleFav = async () => {
    try {
      const response = await toogleFavRequest({ id: selectedRow }).unwrap();
      // console.log({ response });
      if (response?.success) {
        navigate(`/dashboard/packages/`, { state: response });
        // toast.success(response?.message);
        refetch();
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          containerId: "parentToast",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };

  //   const onDeleteDialogClick = async () => {
  //     try {
  //       const response = await deleteRequest({ id: currentItem._id }).unwrap()
  //       if (response.success) {
  //         closeDialog()
  //         toast.success(response?.message)
  //       }
  //     } catch (error) {
  //       toast.error(
  //         error?.data?.message ? error?.data?.message : "No server response",
  //         {
  //           closeOnClick: true,
  //           toastId: 12,
  //           autoClose: 1000
  //         }
  //       )
  //     }
  //   }

  const onSort = (columnName) => {
    setSortQuery((prev) => ({
      field: columnName,
      dir:
        prev.field === columnName
          ? prev.dir === "default"
            ? "asc"
            : prev.dir === "asc"
            ? "dsc"
            : prev.dir === "dsc" && "asc"
          : "asc",
    }));
  };

  useEffect(() => {
    if (state?.success) {
      toast.success(state.message);
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({});
        navigate("/login", { state: { expired: true } });
      }
    }
  }, [error, navigate]);
  return (
    <div className=" flex flex-col gap-1 bg-white shadow-lg">
      <Filters setFilters={setFilters} filters={filters} />
      <ToastContainer autoClose={1000} />
      {isDeleting ? (
        <DeleteDialog
          //   isLoading={isLoadingDeleteMt}
          //   onSubmitForm={onDeleteDialogClick}
          handleClose={closeDialog}
          open={open}
        />
      ) : (
        <Dialog isOpen={open} handleClose={closeDialog}>
          add
        </Dialog>
      )}
      <TableTopHeader
        getSortedData={getFilteredData}
        selectedRow={selectedRow}
        addBtnFav={true}
        removeBtnFav={true}
        onRefreshClick={onRefreshClick}
        searchTitle={"Title"}
        addBtnFavTitle={"Add To Favourite"}
        removeBtnFavTitle={"Remove From Favourtie"}
        // addBtn={true}
        // addBtnTitle={"Create Work Order"}
        refreshBtn={true}
        onToggleFav={onToogleFav}
        // onAddClick={onAddClick}
        setQuery={setSearchQuery}
      />
      {/* table wrapper */}
      <div className="flex flex-col min-h-[25em] justify-between px-2">
        {/* table head and body wrapper */}
        <table className="table-fixed border-collapse w-[100%] max-w-[100%] whitespace-nowrap">
          <TableHeader
            columns={columns}
            onSort={onSort}
            sortQuery={sortQuery}
          />
          <TableBody
            highlight={true}
            // hover={false}
            showEditButton={true}
            showDeleteButton={true}
            // showViewButton={true}
            onViewItem={onViewItemClick}
            onEditItem={onEditItemClick}
            onDeleteItem={onDeleteItemClick}
            columns={columns}
            currentTableData={getFilteredData}
            isLoading={isLoading || isFetching}
            // isLoading={true}
            selectedRows={selectedRow} // Pass selected rows state
            handleRowSelect={handleRowSelect}
          />
        </table>
        <TableFooter
          isLoading={isLoading || isFetching}
          currentPage={currentPage}
          pageSize={pageSize}
          currentTableData={getFilteredData}
          setCurrentPage={goToPage}
          setPageSize={setItemsPerPage}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );
};

export default PackageTable;
