import React from "react"
import logo from "../../../assets/images/logo.png"
import { useSideMenuContext } from "../../../context/SideMenuContext"
const LogoSection = () => {
  const { isMenuOpen } = useSideMenuContext()
  return (
    <div
      className={`flex items-center border-b-2 border-[#b9bbbf] 
      // ${isMenuOpen ? "px-[1.3em] py-[1.3em]" : "px-[1.3em] py-[1.3em]"}
          `}
    >
      <h1
        className={`text-center w-[100%] font-semibold text-[#659aec]  ${
          isMenuOpen ? "text-xl" : "text-xl"
        }`}
      >
        {isMenuOpen ? "RBM TRADING LLC" : "RBM"}
      </h1>
    </div>
  )
}

export default LogoSection
