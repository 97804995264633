import React, { useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { useDialogModel } from "../../../../hooks";
import CheckInComplete from "../../../common/Dialog/CheckInComplete";
import InProccessOfCheckIn from "../../../common/Dialog/InProccessOfCheckIn";
import OrderDelivered from "../../../common/Dialog/OrderDelivered";
import OrderPlaced from "../../../common/Dialog/OrderPlaced";
import OrderShipped from "../../../common/Dialog/OrderShipped";
import ReceivedAtWarehouse from "../../../common/Dialog/ReceivedAtWarehouse";
import { CgCalendarDates } from "react-icons/cg";
import UpdateArrivalDate from "../../../common/Dialog/UpdateArrivalDate";
import UpdateDepartureDate from "../../../common/Dialog/UpdateDepartureDate";
const Actions = ({
  currentItem,
  onSubmitFormCheckInComplete,
  onSubmitFormCheckInProccess,
  onSubmitFormOrderPlaced,
  onSubmitFormOrderShipped,
  onSubmitFormOrderDeleivered,
  onSubmitFormReceivedAtWarehouse,
  onSubmitFormUpdateArrivalDate,
  onSubmitFormUpdateDepartureDate,
  dateUpdate,
  setDateUpdate,
}) => {
  const [open, openDialog, closeDialog] = useDialogModel();
  const [checkInProccess, setCheckInProccess] = useState(false);
  const [checkInComplete, setCheckInComplete] = useState(false);
  const [receivedAtWarehouse, setReceivedAtWarehouse] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderShipped, setOrderShipped] = useState(false);
  const [orderDelivered, setOrderDelivered] = useState(false);
  const [departureDateDialog, setDepartureDateDialog] = useState(false);
  const [arrivalDateDialog, setArrivalDateDialog] = useState(false);
  // console.log({ currentItem })
  const handleCloseDialog = () => {
    setCheckInComplete(false);
    setCheckInProccess(false);
    setOrderPlaced(false);
    setOrderShipped(false);
    setOrderDelivered(false);
    setDepartureDateDialog(false);
    setArrivalDateDialog(false);
    closeDialog();
  };

  const onSubmitCheckInProccess = () => {
    onSubmitFormCheckInProccess();
    handleCloseDialog();
  };
  const onSubmitCheckInComplete = () => {
    onSubmitFormCheckInComplete();
    handleCloseDialog();
  };
  const onSubmitReceivedAtWarehouse = () => {
    onSubmitFormReceivedAtWarehouse();
    handleCloseDialog();
  };
  const onSubmitOrderPlaced = () => {
    onSubmitFormOrderPlaced();
    handleCloseDialog();
  };
  const onSubmitOrderShipped = () => {
    onSubmitFormOrderShipped();
    handleCloseDialog();
  };
  const onSubmitOrderDelivered = () => {
    onSubmitFormOrderDeleivered();
    handleCloseDialog();
  };
  const onSubmitArrivalDate = () => {
    onSubmitFormUpdateArrivalDate();
    handleCloseDialog();
  };
  const onSubmitDepartureDate = () => {
    onSubmitFormUpdateDepartureDate();
    handleCloseDialog();
  };

  return (
    <div className="flex flex-col gap-2 p-4 pb-0">
      {checkInComplete && (
        <CheckInComplete
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitCheckInComplete}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {checkInProccess && (
        <InProccessOfCheckIn
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitCheckInProccess}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {receivedAtWarehouse && (
        <ReceivedAtWarehouse
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitReceivedAtWarehouse}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {orderPlaced && (
        <OrderPlaced
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitOrderPlaced}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {orderShipped && (
        <OrderShipped
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitOrderShipped}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {orderDelivered && (
        <OrderDelivered
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitOrderDelivered}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {arrivalDateDialog && (
        <UpdateArrivalDate
          dateUpdate={dateUpdate}
          setDateUpdate={setDateUpdate}
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitArrivalDate}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      {departureDateDialog && (
        <UpdateDepartureDate
        dateUpdate={dateUpdate}
        setDateUpdate={setDateUpdate}
          //    isLoading={isLoadingDeleteMt}
          onSubmitForm={onSubmitDepartureDate}
          handleClose={handleCloseDialog}
          open={open}
        />
      )}
      <div className="flex flex-row gap-3">
        <button
          onClick={() => {
            setReceivedAtWarehouse(true);
            openDialog();
          }}
          className="px-2 py-1 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-md flex items-center gap-2"
        >
          <IoMdCheckmark /> Received At Warehouse
        </button>
        <button
          onClick={() => {
            setCheckInProccess(true);
            openDialog();
          }}
          className="px-2 py-1 bg-slate-400 hover:bg-slate-500 text-white font-semibold rounded-md flex items-center gap-2"
        >
          <IoMdCheckmark /> In Process Of Check In
        </button>
        <button
          onClick={() => {
            setCheckInComplete(true);
            openDialog();
          }}
          className="px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md flex items-center gap-2"
        >
          <IoMdCheckmark /> Ready for Pick up
        </button>
      </div>
      <div className="flex flex-row gap-3">
        <button
          onClick={() => {
            setOrderPlaced(true);
            openDialog();
          }}
          className="px-2 py-1 bg-orange-400 hover:bg-orange-500 text-white font-semibold rounded-md flex items-center gap-2"
        >
          <IoMdCheckmark /> Order Placed
        </button>
        <button
          onClick={() => {
            setOrderShipped(true);
            openDialog();
          }}
          className="px-2 py-1 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold rounded-md flex items-center gap-2"
        >
          <IoMdCheckmark /> Order Shipped
        </button>
        <button
          onClick={() => {
            setOrderDelivered(true);
            openDialog();
          }}
          className="px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md flex items-center gap-2"
        >
          <IoMdCheckmark /> Order Delivered
        </button>
      </div>
      <hr className="my-2 h-px border-t-2 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-50" />
      <div className="flex flex-row gap-3">
        <button
          onClick={() => {
            setDepartureDateDialog(true);
            openDialog();
          }}
          className="px-2 py-1 bg-gray-200 hover:bg-gray-300 text-black font-semibold rounded-md flex items-center gap-2"
        >
          <CgCalendarDates /> Update Departure Date
        </button>
        <button
          onClick={() => {
            setArrivalDateDialog(true);
            openDialog();
          }}
          className="px-2 py-1 bg-gray-200 hover:bg-gray-300 text-black font-semibold rounded-md flex items-center gap-2"
        >
          <CgCalendarDates /> Update Arrival Date
        </button>
      </div>
    </div>
  );
};

export default Actions;
