import React, { useEffect, useState } from "react"
import Logo from "../../assets/images/logo.png"
import Boxes from "../../assets/images/boxes.png"
import EyeOpen from "../../components/icons/EyeOpen"
import { Model, initialValues, validationSchema } from "../../formModel/auth"
import { useSendLoginMutation } from "../../app/services/authApiSlice"
import { Formik } from "formik"
import { InputFieldWithLabel } from "../../components/common/formElements"
import InputFieldForPassword from "../../components/common/formElements/InputFieldForPassword"
import { ToastContainer, toast } from "react-toastify"
import { BackDropLoader } from "../../components/common/loader"
import { useLocation, useNavigate } from "react-router-dom"
const Login = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  window.history.replaceState({}, document.title)
  const [
    loginRequest,
    { isLoading, isSuccess, isError, isUninitialized, data }
  ] = useSendLoginMutation()

  const {
    formField: { email, password }
  } = Model
  const onFormSubmit = async (values, actions) => {
    try {
      const data = await loginRequest({
        email: values.email,
        password: values.password
      }).unwrap()
      if (data.success) {
        // console.log({})
        localStorage.setItem("admin", JSON.stringify(data.result))
        navigate("/dashboard")
      }
    } catch (error) {
      console.log({ error })
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000
        }
      )
    }
  }

  useEffect(() => {
    if (state?.customer) {
   
      toast.error("Only Admins are allowed to login")
    }
    if (state?.expired) {
     
      toast.error("Login expired . Please Login again")
    }
  }, [state])

  return (
    <div className="h-[100vh] w-[100vw] flex flex-row">
      <ToastContainer autoClose={1000} />
      <div className="basis-[40%] flex  items-center justify-center">
        {isLoading && <BackDropLoader />}
        <div className="w-[50%] flex flex-col gap-[12px]">
          <h1 className="mb-6 text-2xl font-semibold">
            <span className="text-[#9294eb]">Admin </span>Login
          </h1>
          <Formik
            // enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => onFormSubmit(values, actions)}
          >
            {(formik) => {
              // console.log({ formik })
              return (
                <form className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <InputFieldWithLabel
                      fieldlabel={email.label}
                      placeholder={email.label}
                      name={email.name}
                    />
                    <InputFieldForPassword
                      fieldlabel={password.label}
                      placeholder={password.label}
                      name={password.name}
                    />
                  </div>
                  <button
                    type="submit"
                    onClick={formik.handleSubmit}
                    className="text-white bg-[#5b548fea] hover:bg-[#2a2452] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-[11px] text-center "
                  >
                    Login
                  </button>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
      <div className="basis-[60%] bg-[#2a2452] flex justify-center items-center relative">
        <img
          src={Boxes}
          alt="RBM TRADING LLC"
          className="absolute left-0 top-0 w-[100%] h-[100%] opacity-[0.1]"
        />
        <div className="flex">
          <img src={Logo} alt="RBM TRADING LLC" className="" />

          {/* logo */}
        </div>
      </div>
    </div>
  )
}

export default Login
