import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const urlPath = "/stats";

const slice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStats: builder.query({
      query: () => ({
        url: urlPath,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => {
        return res.result;
      },
      providesTags: (result, error, id) => {
        return [{ type: "workorder", id }];
      },
    }),
    getMonthlyInvoice: builder.query({
      query: ({ month, year }) => ({
        url: `/workorders/monthly-invoice?month=${month}&year=${year}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetStatsQuery,
  useGetMonthlyInvoiceQuery,
  useLazyGetMonthlyInvoiceQuery,
} = slice;
