import React from "react"

const PaginationListItem = ({ selected, onClick, item }) => {
  return (
    <li
      onClick={onClick}
      className={` p-3  h-[32px] text-center flex box-border items-center rounded-md border-2 border-slate-500 font-semibold text-[0.9rem] justify-center min-w-[32px] cursor-pointer hover:bg-gray-500 hover:text-white ${
        selected && "bg-slate-500 text-white"
      }`}
    >
      {item}
    </li>
  )
}

export default PaginationListItem
