import React, { useEffect, useState } from "react";
import { useGetStatsQuery } from "../../app/services/statsSlice";
import Card from "./Card";
import BackDropLoader from "../../components/common/loader/BackDropLoader";
import { useNavigate } from "react-router-dom";
import Invoice from "./Invoice";

const DashboardHome = () => {
  const navigate = useNavigate();
  const { isLoading, isSuccess, isFetching, isError, data, refetch, error } =
    useGetStatsQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({});
        navigate("/login", { state: { expired: true } });
      }
    }
  }, [error, navigate]);
  // console.log({ data })
  return (
    <div className="h-[80vh] bg-white  shadow-xl p-6 flex flex-col items-start gap-8">
      <button
        onClick={() => {
          refetch();
        }}
        className="bg-blue-500 px-4 py-2 font-semibold text-white rounded-md hover:bg-blue-600"
      >
        Refresh
      </button>

      {isLoading || isFetching ? (
        <BackDropLoader />
      ) : (
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-4 h-full gap-2 items-stretch">
            {/* <h1 className="text-2xl">{`Total Work Orders : ${data?.workOrdersCount}`}</h1> */}
            <Card title={"Total Work Orders"} value={data?.workOrdersCount} />
            <Card
              title={"Total Work Orders Placed"}
              color={"gray"}
              value={data?.wPlacedCount}
            />
            <Card
              title={"Total Work Orders Shipped"}
              value={data?.wShippedCount}
            />
            <Card
              title={"Total Work Orders Delivered"}
              value={data?.wDeliveredCount}
            />
            <Card
              title={"Total Packages"}
              color={"gray"}
              value={data?.packagesCount}
            />
            <Card
              title={"Total Packages Placed"}
              value={data?.packagePlacedCount}
            />
            <Card
              title={"Total Packages Shipped"}
              color={"gray"}
              value={data?.packageShippedCount}
            />
            <Card
              title={"Total Packages Delivered"}
              value={data?.packageDeliveredCount}
            />
          </div>
          <Invoice />
        </div>
      )}
    </div>
  );
};

export default DashboardHome;
