import { useCallback, useState } from "react"

const useButtonActions = () => {
  const [isEditing, setEditing] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [isDeletingByDate, setDeletingByDate] = useState(false)
  const [isViewing, setViewing] = useState(false)
  const [isAdding, setAdding] = useState(false)
  const [isAddingFee, setAddingFee] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const startEditing = useCallback((item) => {
    setEditing(true)
    setDeleting(false)
    setViewing(false)
    setAdding(false)
    setDeletingByDate(false)
    setAddingFee(false)
    // console.log("editing :", item);
    setCurrentItem(item)
  }, [])

  const startDeleting = useCallback((item) => {
    setEditing(false)
    setDeleting(true)
    setAddingFee(false)
    setViewing(false)
    setAdding(false)
    setDeletingByDate(false)
    setCurrentItem(item)
  }, [])
  const startDeletingByDate = useCallback((item) => {
    setEditing(false)
    setDeleting(true)
    setAddingFee(false)
    setViewing(false)
    setAdding(false)
    setDeletingByDate(true)
    setCurrentItem(null)
  }, [])

  const startViewing = useCallback((item) => {
    setEditing(false)
    setDeleting(false)
    setViewing(true)
    setAdding(false)
    setAddingFee(false)
    setDeletingByDate(false)
    setCurrentItem(item)
  }, [])
  const startAdding = useCallback(() => {
    setEditing(false)
    setDeleting(false)
    setViewing(false)
    setAdding(true)
    setDeletingByDate(false)
    setAddingFee(false)
    setCurrentItem(null)
  }, [])
  const startAddingFee = useCallback(() => {
    setEditing(false)
    setDeleting(false)
    setViewing(false)
    setAdding(false)
    setDeletingByDate(false)
    setAddingFee(true)
    setCurrentItem(null)
  }, [])

  const resetActions = useCallback(() => {
    setEditing(false)
    setDeleting(false)
    setViewing(false)
    setAdding(false)
    setDeletingByDate(false)
    setCurrentItem(null)
    setAddingFee(false)
  }, [])

  return {
    currentItem,
    isEditing,
    isDeleting,
    isAdding,
    isAddingFee,
    isViewing,
    isDeletingByDate,
    startAdding,
    startEditing,
    startDeleting,
    startViewing,
    startAddingFee,
    startDeletingByDate,
    resetActions
  }
}

export default useButtonActions
