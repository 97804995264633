import React, { useEffect, useMemo } from "react"
import {
  TableBody,
  TableFooter,
  TableHeader,
  TableTopHeader
} from "../../common/table"
import { useDebounce } from "../../../hooks"
import useTableData from "../../../hooks/useTableData"
import { columns } from "./config"
import { useGetCustomersQuery } from "../../../app/services/customerSlice"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"

const CustomerTable = () => {
  const navigate = useNavigate()
  const {
    currentPage,
    pageSize,
    query,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery,
    sortQuery
  } = useTableData()

  const debouncedSearchTerm = useDebounce(query, 200)

  const { isLoading, error, isFetching, refetch, data } = useGetCustomersQuery(
    {
      limit: pageSize,
      page: currentPage,
      sort: `${sortQuery.dir === "dsc" ? "-" : ""}${sortQuery.field}`,
      debouncedSearchTerm,
      searchField: "email"
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true
    }
  )

  const getFilteredData = useMemo(() => {
    if (isLoading || isFetching) return []
    if (data?.data?.length > 0) {
      return data?.data?.filter((item) => {
        return item
      })
    } else {
      return []
    }
  }, [isLoading, isFetching, data?.data])

  const onRefreshClick = () => {
    refetch()
    const today = new Date()
    today.setMonth(today.getMonth() - 3)
  }

  const onSort = (columnName) => {
    setSortQuery((prev) => ({
      field: columnName,
      dir:
        prev.field === columnName
          ? prev.dir === "default"
            ? "asc"
            : prev.dir === "asc"
            ? "dsc"
            : prev.dir === "dsc" && "asc"
          : "asc"
    }))
  }

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({})
        navigate("/login", { state: { expired: true } })
      }
    }
  }, [error, navigate])

  return (
    <div className=" flex flex-col gap-1 bg-white shadow-lg">
      <ToastContainer autoClose={1000} />

      <TableTopHeader
        onRefreshClick={onRefreshClick}
        searchTitle={"Email"}
        addBtn={false}
        refreshBtn={true}
        setQuery={setSearchQuery}
      />
      {/* table wrapper */}
      <div className="flex flex-col min-h-[25em] justify-between px-2">
        {/* table head and body wrapper */}
        <table className="table-fixed border-collapse w-[100%] max-w-[100%] whitespace-nowrap">
          <TableHeader
            columns={columns}
            onSort={onSort}
            sortQuery={sortQuery}
          />
          <TableBody
            showEditButton={false}
            showDeleteButton={false}
            showViewButton={false}
            columns={columns}
            currentTableData={getFilteredData}
            isLoading={isLoading || isFetching}
            handleRowSelect={() => {}}
          />
        </table>
        <TableFooter
          isLoading={isLoading || isFetching}
          currentPage={currentPage}
          pageSize={pageSize}
          currentTableData={getFilteredData}
          setCurrentPage={goToPage}
          setPageSize={setItemsPerPage}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  )
}

export default CustomerTable
