import React from "react"

const Dots = ({ onClick, dots }) => {
  return (
    <li
      onClick={onClick}
      className={`p-2 h-[32px] text-center flex box-border justify-center items-center rounded-md border-2 border-gray-200 font-semibold text-[0.9rem] min-w-[32px] cursor-pointer hover:bg-gray-300 hover:border-green-700 ${
        dots && "pointer-events-none"
      }`}
    >
      &#8230;
    </li>
  )
}
export default Dots
