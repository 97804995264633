import React from "react"

const RightArrow = ({ onClick, disabled }) => {
  return (
    <li
      onClick={onClick}
      className={`p-2 h-[32px] text-center border-2 border-gray-200 flex box-border items-center rounded-md font-semibold text-[0.9rem] min-w-[32px] cursor-pointer hover:bg-gray-500 hover:text-white ${
        disabled && "pointer-events-none opacity-[0.3]"
      }`}
    >
      Next
    </li>
  )
}

export default RightArrow
