import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
// import { setCredentials } from "../../features/auth/authSlice"

const baseUrl = `${process.env.REACT_APP_URL}/api/v1`

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = JSON.parse(localStorage.getItem("admin"))
    if (token) {
      headers.set("authorization", `Bearer ${token.accessToken}`)
    }
    return headers
  }
})

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions)
//   if (result?.error?.status === 403) {
//     localStorage.clear()
//     return result
//   }
//   return result
// }

export const apiSlice = createApi({
  reducerPath: "api",
  // baseQuery: baseQueryWithReauth,
  baseQuery,
  tagTypes: ["workorder", "pkg", "wopkg"],
  endpoints: (builder) => ({})
})

//   const createRequest = (url) => ({ url, headers: cryptoApiHeaders })

//   export const cryptoApi = createApi({
//     reducerPath: "cryptoapi",
//     baseQuery: fetchBaseQuery({ baseUrl }),
//     endpoints: (builder) => ({
//       getCryptos: builder.query({
//         query: () => createRequest("/coins"),
//       }),
//     }),
//   })
