import React, { useCallback, useState } from "react"

const useTableData = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [query, setQuery] = useState("")
  const [sortQuery, setSortQueryItem] = useState({
    field: "updatedAt",
    dir: "asc"
  })

  const goToPage = useCallback((page) => {
    setCurrentPage(page)
  }, [])

  const setItemsPerPage = useCallback((size) => {
    setPageSize(size)
    // setCurrentPage(1);
  }, [])

  const setSearchQuery = useCallback((newQuery) => {
    setQuery(newQuery)
    // setCurrentPage(1);
  }, [])
  const setSortQuery = useCallback((newQuery) => {
    setSortQueryItem(newQuery)
    setCurrentPage(1)
  }, [])

  return {
    currentPage,
    pageSize,
    query,
    sortQuery,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery
  }
}

export default useTableData
