import React from "react"
import { DOTS, usePagination } from "../../../hooks/usePagination"
import LeftArrow from "./LeftArrow"
import RightArrow from "./RightArrow"
import Dots from "./Dots"
import PaginationListItem from "./PaginationListItem"

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })


  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className="flex flex-row items-center gap-5 divide-x">
      {/* divider */}
      <div className=""></div>
      {/* pagination container */}
      <ul className=" flex list-none justify-center items-center p-2 m-0 gap-[0.3em]">
        {/* pagination list item */}
        <LeftArrow disabled={currentPage === 1} onClick={onPrevious} />
        {/* pagination range */}
        {paginationRange.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return <Dots key={index} dots={true} />
          }

          // Render our Page Pills
          return (
            <PaginationListItem
              key={index}
              selected={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber)}
              item={pageNumber}
            />
          )
        })}
        {/* pagination list item */}
        <RightArrow disabled={currentPage === lastPage} onClick={onNext} />
      </ul>
    </div>
  )
}

export default Pagination
