import React, { createContext, useCallback, useContext, useMemo, useState } from "react"

const SideMenuUIContext = createContext()
export const useSideMenuContext = () => useContext(SideMenuUIContext)

const SideMenuContext = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toogleMenu = useCallback(() => setIsMenuOpen((p) => !p), [])

  const value = useMemo(
    () => ({
      isMenuOpen,
      toogleMenu
    }),
    [isMenuOpen, toogleMenu]
  )
  return <SideMenuUIContext.Provider value={value}>{children}</SideMenuUIContext.Provider>
}

export default SideMenuContext
