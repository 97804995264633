import React, { useTransition } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useSideMenuContext } from "../../context/SideMenuContext"
import Sidebar from "../../components/dashboard/sidebar/Sidebar"
import Navbar from "../../components/dashboard/navbar/Navbar"
import { BackDropLoader } from "../../components/common/loader"

const DashboardLayout = () => {
  const { isMenuOpen } = useSideMenuContext()
  const navigate = useNavigate()
  const [isPending, startTransition] = useTransition()
  const navigateToPage = (path) => {
    // console.log("path : ", path);

    startTransition(() => {
      navigate(path)
    })
  }
  return (
    // container
    <div className="w-[100%] max-w-[100%]">
      {/* sidebar container */}
      <div
        className={`fixed z-[100] top-0 left-0 h-[100vh] border-r-2 border-[#b9bbbf] b9bbbf] bg-white ${
          isMenuOpen ? "w-[16.5%]" : "w-[7%]"
        }`}
      >
        {/* sidebar */}
        <Sidebar navigateToPage={navigateToPage} />
      </div>
      {/* Main container */}
      <div
        className={`relative  ${
          isMenuOpen ? "w-[83.5%] left-[16.5%]" : "w-[93%] left-[7%]"
        }`}
      >
        {/* nav container */}
        <div className="w-[100%] sticky top-0 z-10 border-b-2 border-[#b9bbbf] bg-white">
          <Navbar />
        </div>
        {/* outlet container */}
        <div className="z-[-10] w-[100%] p-4 bg-slate-50">
          {isPending ? (
            <div>
              <BackDropLoader />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
