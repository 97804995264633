import { Formik } from "formik";
import React from "react";
import {
  Model,
  initialValues,
  validationSchema,
} from "../../../../../formModel/package";
import {
  Datepicker,
  InputFieldWithLabel,
  MultiPicUpload,
  Select,
  TextareaWithLabel,
} from "../../../../common/formElements";
import { BackDropLoader } from "../../../../common/loader";
import PackageCost from "./PackageCost";
import PackageWeightAndDimensions from "./PackageWeightAndDimensions";

const AddPackageDialog = ({
  type: PackageType,
  isLoading,
  onFormSubmit,
  handleClose,
}) => {
  const {
    formField: {
      cost,

      height,
      images,
      type,
      length,
      title,
      packageContent,
      width,
      location,
    },
  } = Model;

  return (
    <div className="flex flex-col gap-8 bg-white h-[95%] overflow-y-auto overflow-x-hidden">
      {isLoading && <BackDropLoader />}

      <div className="p-4 flex flex-col gap-4">
        <h3 className="font-semibold text-2xl capitalize">{`Add Package (${PackageType})`}</h3>

        <div className="">
          <Formik
            // enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => onFormSubmit(values, actions)}
          >
            {(formik) => {
              // console.log({ formik })
              return (
                <form>
                  <div className="flex">
                    <div className="basis-[70%] flex flex-col gap-6">
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-row gap-3">
                          <InputFieldWithLabel
                            fieldlabel={title.label}
                            placeholder={title.label}
                            name={title.name}
                          />
                          <Select
                            fieldlabel={type.label}
                            placeholder={type.placeholder}
                            name={type.name}
                            options={[
                              {
                                value: "box",
                                label: "Box",
                              },
                              {
                                value: "other",
                                label: "Other",
                              },
                            ]}
                          />
                        </div>
                        <PackageWeightAndDimensions />
                        <PackageCost type={PackageType} />
                        <TextareaWithLabel
                          fieldlabel={packageContent.label}
                          placeholder={packageContent.label}
                          name={packageContent.name}
                        />
                        <div className="flex flex-row gap-3">
                          <InputFieldWithLabel
                            fieldlabel={location.label}
                            placeholder={location.label}
                            name={location.name}
                          />
                        </div>
                        <div className="flex flex-row ">
                          <MultiPicUpload
                            fieldlabel={images.label}
                            placeholder={images.label}
                            name={images.name}
                          />
                        </div>
                        <button
                          type="submit"
                          onClick={formik.handleSubmit}
                          className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddPackageDialog;
