import React from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useGetWorkorderByIdQuery,
  useGetWorkordersQuery,
  useUpdateWorkOrderMutation,
} from "../../../../app/services/workOrderSlice";
import { BackDropLoader } from "../../../common/loader";
import { IoMdArrowBack } from "react-icons/io";
import {
  Model,
  initialValues,
  validationSchema,
} from "../../../../formModel/workOrder";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Datepicker,
  InputFieldWithLabel,
  MultiPicUpload,
  MultiPicUploadForUpdate,
  Select,
} from "../../../common/formElements";

const EditWorkOrder = () => {
  const { id } = useParams();

  const { data, isLoading, isSuccess } = useGetWorkorderByIdQuery(id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const navigate = useNavigate();

  const [updateRequest, updateRequestQueryResult] =
    useUpdateWorkOrderMutation();
  const {
    formField: {
      arrivalDate,
      departureDate,
      images,
      status,
      title,
      trackingId,
      type,
      cargoAvailableStatus,
    },
  } = Model;

  const onFormSubmit = async (values, actions) => {
    try {
      const response = await updateRequest({ ...values, id }).unwrap();
      if (response.success) {
        navigate("/dashboard/work-orders", { state: response });
      }
    } catch (error) {
      if (!toast.isActive(12, "error")) {
        toast.error(
          error?.data?.message ? error?.data?.message : "No server response",
          {
            closeOnClick: true,
            toastId: 12,
            autoClose: 1000,
          }
        );
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4">
        {isLoading && <BackDropLoader />}
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
      </div>
    );
  }
  if (isSuccess) {
    return data ? (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4">
        {updateRequestQueryResult.isLoading && <BackDropLoader />}
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        <div className="p-4 flex flex-col gap-4">
          <h3 className="font-semibold text-2xl">Edit Work Order</h3>

          <div className="">
            <Formik
              enableReinitialize={true}
              initialValues={{
                [title.name]: data.title,
                [type.name]: data.type,
                [status.name]: data.status,
                [cargoAvailableStatus.name]: data.cargoAvailableStatus,
                [trackingId.name]: data.trackingId,
                [departureDate.name]: new Date(data.departureDate),
                // [departureDate.name]: "",
                [arrivalDate.name]: new Date(data.arrivalDate),
                // [arrivalDate.name]: "",
                [images.name]: [...data.pics_url],

                // [images.name]: []
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => onFormSubmit(values, actions)}
            >
              {(formik) => {
                return (
                  <form>
                    <ToastContainer containerId={"error"} />
                    <div className="flex">
                      <div className="basis-[70%] flex flex-col gap-6">
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-7">
                            <InputFieldWithLabel
                              fieldlabel={title.label}
                              placeholder={title.label}
                              name={title.name}
                            />
                            <Select
                              fieldlabel={type.label}
                              placeholder={type.placeholder}
                              name={type.name}
                              options={[
                                {
                                  value: "consolidation",
                                  label: "Consolidation",
                                },
                                {
                                  value: "express",
                                  label: "Express",
                                },
                              ]}
                            />
                          </div>
                          <div className="flex flex-row gap-7">
                            <InputFieldWithLabel
                              fieldlabel={trackingId.label}
                              placeholder={trackingId.label}
                              name={trackingId.name}
                            />
                            <Select
                              fieldlabel={cargoAvailableStatus.label}
                              placeholder={cargoAvailableStatus.placeholder}
                              name={cargoAvailableStatus.name}
                              options={[
                                {
                                  value: "receivedAtWarehouse",
                                  label: "Received At Warehouse",
                                },
                                {
                                  value: "inProcessOfCheckIn",
                                  label: "In Process Of Check In",
                                },

                                {
                                  value: "checkInComplete",
                                  label: "Ready for Pick up",
                                },
                              ]}
                            />
                          </div>
                          <div className="flex flex-row gap-7">
                            <Select
                              fieldlabel={status.label}
                              placeholder={status.placeholder}
                              name={status.name}
                              options={[
                                {
                                  value: "placed",
                                  label: "Order Placed",
                                },
                                {
                                  value: "shipped",
                                  label: "Order Shipped",
                                },
                                {
                                  value: "delivered",
                                  label: "Order Delivered",
                                },
                              ]}
                            />
                            <Datepicker
                              fieldlabel={departureDate.label}
                              placeholder={departureDate.label}
                              name={departureDate.name}
                            />
                          </div>
                          <div className="flex flex-row gap-7">
                            <Datepicker
                              fieldlabel={arrivalDate.label}
                              placeholder={arrivalDate.label}
                              name={arrivalDate.name}
                            />
                            <div className="flex flex-col items-start gap-[0.1em] w-[100%]"></div>
                          </div>

                          <div className="flex flex-row gap-7">
                            <MultiPicUploadForUpdate
                              fieldlabel={images.label}
                              placeholder={images.label}
                              name={images.name}
                            />
                          </div>
                          <button
                            type="submit"
                            onClick={formik.handleSubmit}
                            className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 h-96">
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        No work Order found
      </div>
    );
  }
  if (!isSuccess && !isLoading) {
    return (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 h-96">
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        No work Order found
      </div>
    );
  }
};

export default EditWorkOrder;
