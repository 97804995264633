import React from "react"
import CustomerTable from "../../components/dashboard/customers/CustomerTable"

const Customer = () => {
  return (
    <div>
      <CustomerTable />
    </div>
  )
}

export default Customer
