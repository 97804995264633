import React from "react"
import PackageTable from "../../components/dashboard/packages/PackageTable"

const Package = () => {
  return (
    <div className="bg-white">
      <h1 className="font-semibold text-[2rem] p-5">Packages List</h1>
      <PackageTable />
    </div>
  )
}

export default Package
