import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import Logo from "../../../../assets/images/rbmlogo.png";
import { DateFormat } from "../../../../utils/format";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const GenerateInvoice = ({ data }) => {
  const onHandleDownloadInvoice = () => {
    console.log("Donwload invoice");
    // openDialog();
    html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: [612, 792],
      });
      pdf.internal.scaleFactor = 1;
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(
        `INVOICE-${data?.title.replace(/\s+/g, "")}-${data?.trackingId}.pdf`
      );
    });
  };

  const currentDate = new Date().toLocaleDateString();
  return (
    <div className=" w-[80%] m-auto mt-10 flex gap-4 flex-col">
      <div
        className=" flex flex-col gap-10 px-16 py-10  border-2 border-gray-200 rounded-md"
        id="invoiceCapture"
      >
        <img className=" w-[5em] self-end" src={Logo} alt="logo" />
        <div className="flex justify-between items-center">
          <div className="text-[1rem] font-bold  tracking-wider">
            <h3>Bill to</h3>
            <h3>Customer : {data.title}</h3>
            <h3>Tracking id : {data.trackingId}</h3>
          </div>
          <div className="text-[1rem] font-bold  tracking-wider">
            <h4>Invoice Date : {currentDate}</h4>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="flex flex-col font-medium gap-1 w-[40%]">
            <div className="flex justify-between">
              <p>Cargo Status</p>
              <p>
                {data?.cargoAvailableStatus === "inProcessOfCheckIn"
                  ? "In Process Of Check In"
                  : data?.cargoAvailableStatus === "receivedAtWarehouse"
                  ? "Received At Warehouse"
                  : "Ready For Pick Up"}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Final Status</p>
              <p>
                {data?.status === "placed"
                  ? "Placed"
                  : data?.status === "shipped"
                  ? "Shipped"
                  : "Delivered"}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Arrival Date</p>
              <p>{DateFormat(data?.arrivalDate, true)}</p>
            </div>
            <div className="flex justify-between">
              <p>Departure Date</p>
              <p>{DateFormat(data?.departureDate, true)}</p>
            </div>
            <div className="flex justify-between">
              <p>Total Weight</p>
              <p>{`${data?.total_weight} Lbs`}</p>
            </div>
          </div>
          <div className="flex flex-col font-medium gap-4 w-[35%]">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between">
                <p>Import Fee </p> <p>{`${data?.import_fee} USD`}</p>
              </div>
              {/* <hr className="my-1 h-[0.05em]  bg-slate-100   " /> */}
              <div className="flex flex-row justify-between">
                <p>Handling Fee </p> <p>{`${data?.handling_fee} USD`}</p>
              </div>
              {/* <hr className="my-1 h-[0.09em]  bg-slate-200   " /> */}
              <div className="flex flex-row justify-between">
                <p>Duty Fee </p> <p>{`${data?.duty} USD`}</p>
              </div>
              {data?.miscellaneous_fees_one && (
                <div className="flex flex-row justify-between">
                  <p>Miscellaneous Fees 1 </p>{" "}
                  <p>{`${data?.miscellaneous_fees_one} USD`}</p>
                </div>
              )}
              {data?.miscellaneous_fees_two && (
                <div className="flex flex-row justify-between">
                  <p>Miscellaneous Fees 2 </p>{" "}
                  <p>{`${data?.miscellaneous_fees_two} USD`}</p>
                </div>
              )}
              {data?.miscellaneous_fees_three && (
                <div className="flex flex-row justify-between">
                  <p>Miscellaneous Fees 3 </p>{" "}
                  <p>{`${data?.miscellaneous_fees_three} USD`}</p>
                </div>
              )}
            </div>
            <div>
              <hr className="my-3 h-[0.09em]  bg-slate-500   " />
              <div className="flex flex-row justify-between">
                <p>Total Cost </p> <p>{`${data?.total_cost} USD`}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div>Charges</div> */}
      </div>
      <button
        onClick={onHandleDownloadInvoice}
        className={`self-end font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white  bg-blue-500 hover:bg-blue-600`}
      >
        <MdOutlineFileDownload size={18} />
        Download Invoice
      </button>
    </div>
  );
};

export default GenerateInvoice;
