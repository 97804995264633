import React, { useState } from "react"
import Pagination from "./Pagination"
import Select from "react-select"
const options = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 75, label: "75" },
  { value: 100, label: "100" },
  { value: 200, label: "200" }
]

const colourStyles = {
  control: (base, state) => ({
    ...base,
    fontWeight: 500,
    fontSize: "0.875rem",

    background: "#f9fafb",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // // Overwrittes the different states of border
    borderColor: state.isFocused ? "#3f4042" : "#d1d5db",
    // // Removes weird border around container
    // boxShadow: state.isFocused ? null : null,
    border: state.isFocused && "2px solid",
    // This line disable the blue border
    boxShadow: "none",
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "red" : "blue"
    }
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    padding: 5
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      marginTop: 1,
      fontSize: "0.875rem",
      fontWeight: 500,
      backgroundColor: "white",
      color: "black",
      // ...(isSelected && {
      //   backgroundColor: "red",
      //   color: "white"
      // }),
      ...(isFocused && {
        backgroundColor: "#6666ff",
        color: "white"
      }),
      // backgroundColor: isDisabled ? 'red' : 'blue',
      // color: '#FFF',
      // cursor: isDisabled ? 'not-allowed' : 'default',
      "&:hover": {
        backgroundColor: "#6666ff",
        color: "white"
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }
  }
}

const TableFooter = ({
  isLoading,
  setPageSize,
  setCurrentPage,
  pageSize,
  currentPage,
  currentTableData,
  totalCount
}) => {
  const [selectPageSize, setSelectPageSize] = useState({
    value: 10,
    label: "10"
  })
  // console.log({ currentPage, currentTableData });
  // if (isLoading) {
  //   return (
  //     <Box
  //       sx={{
  //         padding: "1.5em 0em",
  //         display: "flex",
  //         justifyContent: "center",
  //       }}
  //     >
  //       {" "}
  //       <DashboardLoader />
  //     </Box>
  //   );
  // }

  const onSelectChange = ({ value, label }) => {
    setCurrentPage(1)
    setPageSize(Number(value))
    setSelectPageSize({ value, label })
  }

  const onInputPageNumberchange = (event) => {
    if (event.key === "Enter") {
      const totalNumberOFPages = Math.ceil(totalCount / pageSize)
      if (Number(event.target.value) > totalNumberOFPages) {
        setCurrentPage(Number(totalNumberOFPages))
      } else {
        setCurrentPage(Number(event.target.value))
      }
    }
  }
 
  return (
    // table footer wrapper

    <div className="flex flex-row justify-between items-center px-1 py-2 w-full">
      <div className="flex flex-row items-center gap-2 ">
        {currentTableData.length > 0 && (
          <>
            <p>Show</p>
            <Select
              value={selectPageSize}
              // placeholder={"Select number of pages"}
              defaultValue={{ value: 10, label: "10" }}
              onChange={onSelectChange}
              options={options}
              styles={colourStyles}
            />
            <p>entires</p>
          </>
        )}
      </div>
      <div className="flex flex-row items-center gap-2 mt-4">
        {currentTableData.length > 0 && (
          <div className="flex gap-2 flex-row items-center mr-4">
            <p>Go to Page</p>
            <input
              type="text"
              className="w-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[3px] block p-[0.52em] font-semibold text-[1rem] focus:outline-gray-800"
              onKeyDown={onInputPageNumberchange}
            />
            <p>Number</p>
          </div>
        )}
        {currentTableData?.length > 0 && (
          <div className="font-semibold text-[0.85rem]">
            {(currentPage - 1) * pageSize + 1} -{" "}
            {(currentPage - 1) * pageSize + currentTableData?.length} of{" "}
            {totalCount}
          </div>
        )}
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  )
}

export default TableFooter
