import React from "react"

const TableEmptyRow = () => {
  return (
    <tbody className="flex flex-col h-[100%] overflow-y-auto">
      <tr className="mb-32 p-2">
        <td className="flex justify-center items-center text-[0.92rem] font-semibold ">No data found</td>
      </tr>
    </tbody>
  )
}

export default TableEmptyRow
