import { Formik } from "formik";
import React from "react";
import { Model, validationSchema } from "../../../../../formModel/package";
import {
  Datepicker,
  InputFieldWithLabel,
  MultiPicUploadForUpdate,
  Select,
} from "../../../../common/formElements";
import { BackDropLoader } from "../../../../common/loader";
import PackageWeightAndDimensions from "./PackageWeightAndDimensions";
import PackageCost from "./PackageCost";

const EditWorkOrderPackage = ({
  isLoading,
  onFormSubmit,
  handleClose,
  currentItem,
  type: PackageType,
}) => {
  const {
    formField: {
      cost,
      height,
      images,
      customCostPerPound,
      type,
      packageContent,
      wantToUseCalculator,
      weight,
      length,
      title,
      width,
      location,
    },
  } = Model;
  // console.log({ currentItem })

  //   const onIdCardChange = (e) => {
  //     const onlyNums = e.target.value.replace(/[^0-9]/g, "")
  //     setFieldValue(cnic.name, cnicInputFormat(onlyNums))
  //   }

  return (
    <div className="flex flex-col gap-8 bg-white h-[95%] overflow-y-auto overflow-x-hidden">
      {isLoading && <BackDropLoader />}

      <div className="p-4 flex flex-col gap-4">
        <h3 className="font-semibold text-2xl capitalize">{`Edit Package (${PackageType})`}</h3>

        <div className="">
          <Formik
            enableReinitialize={true}
            initialValues={{
              [title.name]: currentItem.title,
              [height.name]: currentItem.height,
              [width.name]: currentItem.width,
              [length.name]: currentItem.length,
              [cost.name]: currentItem.cost,
              [type.name]: currentItem.type,
              [packageContent.name]: currentItem.package_content,
              [weight.name]: currentItem.weight,
              [location.name]: currentItem.location,
              // [lastFreeDay.name]: currentItem?.last_free_day
              //   ? new Date(currentItem?.last_free_day)
              //   : new Date(),
              [images.name]: [...currentItem.pics_url],
              [customCostPerPound.name]: currentItem?.custom_cost_per_pound,
              [wantToUseCalculator.name]: currentItem?.want_to_use_calculator,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => onFormSubmit(values, actions)}
          >
            {(formik) => {
              return (
                <form>
                  <div className="flex">
                    <div className="basis-[70%] flex flex-col gap-6">
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-3">
                          <InputFieldWithLabel
                            fieldlabel={title.label}
                            placeholder={title.label}
                            name={title.name}
                          />
                          <Select
                            fieldlabel={type.label}
                            placeholder={type.placeholder}
                            name={type.name}
                            options={[
                              {
                                value: "box",
                                label: "Box",
                              },
                              {
                                value: "other",
                                label: "Other",
                              },
                            ]}
                          />
                        </div>
                        <PackageWeightAndDimensions />
                        <PackageCost type={PackageType} />
                        <InputFieldWithLabel
                          fieldlabel={packageContent.label}
                          placeholder={packageContent.label}
                          name={packageContent.name}
                        />
                        <div className="flex flex-row gap-3">
                          <InputFieldWithLabel
                            fieldlabel={location.label}
                            placeholder={location.label}
                            name={location.name}
                          />
                        </div>
                        <div className="flex flex-row">
                          <MultiPicUploadForUpdate
                            fieldlabel={images.label}
                            placeholder={images.label}
                            name={images.name}
                          />
                        </div>
                        <button
                          type="submit"
                          onClick={formik.handleSubmit}
                          className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditWorkOrderPackage;
