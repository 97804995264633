import { useField } from "formik"
import React, { useEffect, useRef, useState } from "react"
import Label from "./Label"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { IoMdClose } from "react-icons/io"

const MultiPicUpload = ({ fieldlabel, ...props }) => {
  const [field, { value }, { setValue }] = useField(props)
  const [hover, setHover] = useState(null)
  const isFirstRender = useRef(true)

  // console.log({ value })
  const [files, setFile] = useState([])
  const handleFile = (e) => {
    let file = e.target.files
    if (file.length + files.length > 3) {
      toast.error("Maximum 3 images allowed")
      return
    }
    for (let i = 0; i < file.length; i++) {
      const fileType = file[i]["type"]
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"]
      if (validImageTypes.includes(fileType)) {
        setFile((old) => [...old, file[i]])
      } else {
        toast.error("Please upload only image files.")
      }
    }
  }

  const removeImage = (i) => {
    setFile(files.filter((x, index) => index !== i))
    setHover(null)
  }

  useEffect(() => {
    setValue(files)
  }, [files, setValue])

  return (
    <div className="flex flex-col items-start gap-[0.1em] w-[100%]">
      <ToastContainer />
      <Label label={fieldlabel} />
      <div className="flex flex-col w-[100%]">
        <div className="rounded-lg bg-gray-50 w-full">
          <div className="m-4">
            <div className="flex items-center justify-center w-full">
              <label className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    Select a photo
                  </p>
                </div>
                <input
                  type="file"
                  onChange={handleFile}
                  className="opacity-0"
                  multiple="multiple"
                  //   name="files[]"
                />
              </label>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {files.length > 0 &&
                files.map((file, key) => {
                  return (
                    <div
                      onMouseEnter={() => {
                        setHover(key)
                      }}
                      onMouseLeave={() => setHover(null)}
                      key={key}
                      className={`h-20 w-20 relative  cursor-pointer`}
                    >
                      <div
                        className={`absolute w-full h-full flex items-center justify-center`}
                      >
                        <IoMdClose
                          className={`z-50 opacity-0 text-3xl ${
                            hover === key && "opacity-100"
                          }`}
                          onClick={() => {
                            removeImage(key)
                          }}
                        />
                      </div>
                      <img
                        className={`w-full h-full  rounded-md object-cover ${
                          hover === key && "blur-sm opacity-40"
                        }`}
                        alt="pic"
                        src={URL.createObjectURL(file)}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultiPicUpload
