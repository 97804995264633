import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  Model,
  initialValues,
  validationSchema,
} from "../../../../formModel/workOrder";
import {
  Datepicker,
  InputFieldWithLabel,
  Select,
  MultiPicUpload,
} from "../../../common/formElements";
import { useAddWorkOrderMutation } from "../../../../app/services/workOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BackDropLoader } from "../../../common/loader";

const CreateWorkOrder = () => {
  const navigate = useNavigate();

  const [addRequest, { error, isLoading: isLoadingUpdateMt }] =
    useAddWorkOrderMutation();
  const {
    formField: {
      arrivalDate,
      departureDate,
      images,
      status,
      title,
      trackingId,
      type,
      cargoAvailableStatus,
    },
  } = Model;

  const onFormSubmit = async (values, actions) => {
    try {
      const response = await addRequest(values).unwrap();
      if (response.success) {
        console.log({ response });
        if (response?.result?.id) {
          navigate(`/dashboard/work-orders/view/${response?.result?.id}`, {
            state: response,
          });
        } else {
          navigate("/dashboard/work-orders", { state: response });
        }
      }
    } catch (error) {
      if (!toast.isActive(12, "error")) {
        toast.error(
          error?.data?.message ? error?.data?.message : "No server response",
          {
            closeOnClick: true,
            toastId: 12,
            autoClose: 1000,
          }
        );
      }
    }
  };

  const LoadConsolidationTemplate = ({ setValues }, event) => {
    event.preventDefault();
    setValues({
      ...initialValues,
      [type.name]: "consolidation",
      [status.name]: "placed",
      [cargoAvailableStatus.name]: "receivedAtWarehouse",
    });
  };
  const LoadExpressTemplate = ({ setValues }, event) => {
    event.preventDefault();
    setValues({
      ...initialValues,
      [type.name]: "express",
      [status.name]: "placed",
      [cargoAvailableStatus.name]: "receivedAtWarehouse",
    });
  };

  return (
    <div className="flex flex-col gap-8 bg-white shadow-lg p-4">
      {isLoadingUpdateMt && <BackDropLoader />}
      <div className="self-start flex items-center gap-1 text-blue-500 ">
        <IoMdArrowBack size={15} />

        <div
          onClick={() => {
            navigate("/dashboard/work-orders");
          }}
          className="hover:border-b-2 cursor-pointer border-blue-600"
        >
          Back to List of Work Orders
        </div>
      </div>
      <div className="p-4 flex flex-col gap-4">
        <h3 className="font-semibold text-2xl">Create Work Order</h3>

        <div className="">
          <Formik
            // enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => onFormSubmit(values, actions)}
          >
            {(formik) => {
              return (
                <form>
                  <ToastContainer containerId={"error"} />

                  <div className="flex">
                    <div className="basis-[70%] flex flex-col gap-6">
                      <div className="flex gap-2">
                        <button
                          onClick={(e) => LoadConsolidationTemplate(formik, e)}
                          className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                        >
                          Load Consolidation template
                        </button>
                        <button
                          onClick={(e) => LoadExpressTemplate(formik, e)}
                          className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                        >
                          Load Express template
                        </button>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-7">
                          <InputFieldWithLabel
                            fieldlabel={title.label}
                            placeholder={title.label}
                            name={title.name}
                          />
                          <Select
                            fieldlabel={type.label}
                            placeholder={type.placeholder}
                            name={type.name}
                            options={[
                              {
                                value: "consolidation",
                                label: "Consolidation",
                              },
                              {
                                value: "express",
                                label: "Express",
                              },
                            ]}
                          />
                        </div>
                        <div className="flex flex-row gap-7">
                          <InputFieldWithLabel
                            fieldlabel={trackingId.label}
                            placeholder={trackingId.label}
                            name={trackingId.name}
                          />
                          <Select
                            fieldlabel={cargoAvailableStatus.label}
                            placeholder={cargoAvailableStatus.placeholder}
                            name={cargoAvailableStatus.name}
                            options={[
                              {
                                value: "receivedAtWarehouse",
                                label: "Received At Warehouse",
                              },
                              {
                                value: "inProcessOfCheckIn",
                                label: "In Process Of Check In",
                              },

                              {
                                value: "checkInComplete",
                                label: "Ready for Pick up",
                              },
                            ]}
                          />
                        </div>
                        <div className="flex flex-row gap-7">
                          <Select
                            fieldlabel={status.label}
                            placeholder={status.placeholder}
                            name={status.name}
                            options={[
                              {
                                value: "placed",
                                label: "Order Placed",
                              },
                              {
                                value: "shipped",
                                label: "Order Shipped",
                              },
                              {
                                value: "delivered",
                                label: "Order Delivered",
                              },
                            ]}
                          />
                          <Datepicker
                            fieldlabel={departureDate.label}
                            placeholder={departureDate.label}
                            name={departureDate.name}
                          />
                        </div>
                        <div className="flex flex-row gap-7">
                          <Datepicker
                            fieldlabel={arrivalDate.label}
                            placeholder={arrivalDate.label}
                            name={arrivalDate.name}
                          />
                          <div className="flex flex-col items-start gap-[0.1em] w-[100%]"></div>
                        </div>

                        <div className="flex flex-row gap-7">
                          <MultiPicUpload
                            fieldlabel={images.label}
                            placeholder={images.label}
                            name={images.name}
                          />
                        </div>
                        <button
                          type="submit"
                          onClick={formik.handleSubmit}
                          className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateWorkOrder;
