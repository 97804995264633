import React from "react"
import Dialog from "./Dialog"
import { BackDropLoader } from "../loader"

const OrderShipped = ({ open, handleClose, onSubmitForm, isLoading }) => {
  return (
    <Dialog small={true} isOpen={open} handleClose={handleClose}>
      {isLoading && <BackDropLoader />}
      <div className="flex h-full w-full flex-col justify-center gap-5">
        <p className="font-semibold text-center">Are you sure</p>
        <div className=" flex flex-col gap-1">
          <button
            onClick={onSubmitForm}
            className="bg-emerald-500 hover:bg-emerald-600 font-semibold text-white py-1 rounded-md"
          >
            Order Shipped
          </button>
          <button
            className="bg-gray-200 py-1 font-semibold rounded-md hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default OrderShipped
