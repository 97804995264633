import { useField } from "formik"
import ErrorMessage from "./ErrorMessage"
import React from "react"
import Label from "./Label"

const InputFieldWithLabel = ({ fieldlabel, priceField, ...props }) => {
  const [field, { error, touched }] = useField(props)

  // console.log({ inputField: field })
  return (
    <div className="flex flex-col items-start gap-[0.1em] w-[100%]">
      <Label label={fieldlabel} />
      <div className="flex flex-col w-[100%] relative">
        {priceField && !error && (
          <div className="absolute right-0 flex items-center text-[0.85rem] font-semibold h-full px-2 capitalize">
            USD
          </div>
        )}
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[3px] block w-full px-[0.52em] py-[0.65em] font-semibold text-[1rem] focus:outline-gray-800"
          type="text"
          {...field}
          {...props}
        />
        <ErrorMessage error={error} touched={touched} />
      </div>
    </div>
  )
}

export default InputFieldWithLabel
