import * as Yup from "yup"

export const Model = {
  formField: {
    importFee: {
      name: "import_fee",
      label: "Import Fee"
    },
    handlingFee: {
      name: "handling_fee",
      label: "Handling Fee"
    },
    duty: {
      name: "duty",
      label: "Duty"
    },
    miscellaneousFeesOne: {
      name: "miscellaneous_fees_one",
      label: "Miscellaneous Fees (1)"
    },
    miscellaneousFeesOneNote: {
      name: "miscellaneous_fees_one_note",
      label: "Miscellaneous Fees (1) Note"
    },
    miscellaneousFeesTwo: {
      name: "miscellaneous_fees_two",
      label: "Miscellaneous Fees (2)"
    },
    miscellaneousFeesTwoNote: {
      name: "miscellaneous_fees_two_note",
      label: "Miscellaneous Fees (2) Note"
    },
    miscellaneousFeesThree: {
      name: "miscellaneous_fees_three",
      label: "Miscellaneous Fees (3)"
    },
    miscellaneousFeesThreeNote: {
      name: "miscellaneous_fees_three_note",
      label: "Miscellaneous Fees (3) Note"
    },
    wantToUseCalculator: {
      name: "wantToUseCalculator",
      label: "Would you like to use the cost calculator",
      desc: "Check this to enable the cost calculator"
    }
  }
}

const {
  formField: {
    duty,
    wantToUseCalculator,
    handlingFee,
    miscellaneousFeesOne,
    miscellaneousFeesThree,
    miscellaneousFeesTwo,
    miscellaneousFeesOneNote,
    miscellaneousFeesThreeNote,
    miscellaneousFeesTwoNote,
    importFee
  }
} = Model

export const initialValues = {
  [importFee.name]: "",
  [handlingFee.name]: "",
  [duty.name]: "",
  [wantToUseCalculator.name]: true,
  [miscellaneousFeesOne.name]: "",
  [miscellaneousFeesTwo.name]: "",
  [miscellaneousFeesThree.name]: "",
  [miscellaneousFeesOneNote.name]: "",
  [miscellaneousFeesTwoNote.name]: "",
  [miscellaneousFeesThreeNote.name]: ""
}

export const validationSchema = Yup.object({
  [importFee.name]: Yup.string().required("Import fee is required"),
  [handlingFee.name]: Yup.string().required("Handling fee is required"),
  [miscellaneousFeesOne.name]: Yup.string(),
  [miscellaneousFeesTwo.name]: Yup.string(),
  [miscellaneousFeesThree.name]: Yup.string(),
  [miscellaneousFeesOneNote.name]: Yup.string(),
  [miscellaneousFeesTwoNote.name]: Yup.string(),
  [miscellaneousFeesThreeNote.name]: Yup.string(),
  [duty.name]: Yup.string(),
  [wantToUseCalculator.name]: Yup.boolean()
})
