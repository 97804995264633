import React from "react"
import WorkOrderTable from "../../components/dashboard/workOrder/list/WorkOrderTable"

const WorkOrder = () => {
  return (
    <div className="">
      <WorkOrderTable />
    </div>
  )
}

export default WorkOrder
