import React, { useState } from "react";
import SelectDropDown from "react-select";
const statusColourStyles = {
  control: (base, state) => ({
    ...base,
    fontWeight: 500,
    minWidth: "150px",
    fontSize: "0.875rem",
    background: "#f9fafb",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // // Overwrittes the different states of border
    borderColor: state.isFocused ? "#3f4042" : "#d1d5db",
    // // Removes weird border around container
    // boxShadow: state.isFocused ? null : null,
    border: state.isFocused && "2px solid",
    // This line disable the blue border
    boxShadow: "none",
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "red" : "blue"
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    padding: 5,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      marginTop: 1,
      fontSize: "0.9rem",
      fontWeight: 500,
      backgroundColor: "white",
      color: "black",
      // ...(isSelected && {
      //   backgroundColor: "red",
      //   color: "white"
      // }),
      ...(isFocused && {
        backgroundColor: "#6666ff",
        color: "white",
      }),
      // backgroundColor: isDisabled ? 'red' : 'blue',
      // color: '#FFF',
      // cursor: isDisabled ? 'not-allowed' : 'default',
      "&:hover": {
        backgroundColor: "#6666ff",
        color: "white",
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    };
  },
};

const favoritesOptions = [
  {
    value: "in-favorites",
    label: "In Favorites",
  },
  {
    value: "not-in-favorites",
    label: "Not In Favorites",
  },
];
const typeOptions = [
  {
    value: "other",
    label: "Other",
  },
  {
    value: "box",
    label: "Box",
  },
];
// { type: null, status: null, cargoAvailableStatus: null }
const Filters = ({ setFilters, filters }) => {
  const onFavoriteChange = ({ value }) => {
    setFilters((filters) => {
      return { ...filters, favorite: value };
    });
  };

  const onTypeChange = ({ value }) => {
    setFilters((filters) => {
      return { ...filters, type: value };
    });
  };
  const OnClearFilters = () => {
    setFilters({
      type: null,
      favorite: null,
    });
  };
  return (
    <div className="flex flex-row gap-3 justify-end pb-0">
      {(filters.favorite || filters.type) && (
        <button
          className="font-semibold text-[0.875rem] bg-zinc-200 rounded-md py-2 px-4 hover:bg-gray-300"
          onClick={OnClearFilters}
        >
          Clear Filters
        </button>
      )}
      <SelectDropDown
        placeholder={"Filter By Package Type"}
        defaultValue={null}
        value={typeOptions.filter((item) => item.value === filters.type)}
        onChange={onTypeChange}
        options={typeOptions}
        styles={statusColourStyles}
      />
      <SelectDropDown
        placeholder={"Filter By Favorites"}
        value={favoritesOptions.filter((item) => item.value === filters.favorite)}
        defaultValue={null}
        onChange={onFavoriteChange}
        options={favoritesOptions}
        styles={statusColourStyles}
      />
    </div>
  );
};

export default Filters;
