export const textSorting = (a, b, dir) => {
   
    if (dir === "asc") {
      if (a && !b) return -1
      if (!a && b) return 1
    }
    if (dir === "dsc") {
      if (a && !b) return 1
      if (!a && b) return -1
    }
    if (a && b) {
      return a.localeCompare(b)
    }
    return 0
  }