
import { apiSlice } from "./apiSlice"

const urlPath = "/customers"

const slice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({ limit, page, sort, debouncedSearchTerm, searchField }) => ({
        url: (() => {
          let queryString = `${urlPath}?role=customer&select=-password&limit=${limit}&page=${page}&sort=${sort}`

          if (debouncedSearchTerm) {
            queryString += `&search=${debouncedSearchTerm}&searchField=${searchField}`
          }
          return queryString
        })()
      }),
      transformResponse: (res) => {
        return res.result
      }
    })
  })
})

export const { useGetCustomersQuery } = slice
