import React from "react"
import { GrMenu } from "react-icons/gr"
import { useSideMenuContext } from "../../../context/SideMenuContext"
import Breadcrumb from "./Breadcrumb"
const Navbar = () => {
  const { isMenuOpen, toogleMenu } = useSideMenuContext()
  return (
    <div className="flex py-[0.7em] px-[0.8em] items-center gap-8">
      <div
        className={`cursor-pointer p-2 rounded-[4px] transition-all duration-150 hover:bg-[#4372bb] hover:text-white`}
        onClick={toogleMenu}
      >
        <GrMenu />
      </div>
      <div>
        <Breadcrumb />
      </div>
    </div>
  )
}

export default Navbar
