import * as Yup from "yup"

export const Model = {
  formField: {
    title: {
      name: "title",
      label: "Title"
    },
    type: {
      name: "type",
      label: "Type",
      placeholder: "Select Type"
    },
    trackingId: {
      name: "trackingId",
      label: "Tracking Id"
    },
    status: {
      name: "status",
      label: "Status",
      placeholder: "Select Status"
    },
    cargoAvailableStatus: {
      name: "cargoAvailableStatus",
      label: "Cargo Available Status",
      placeholder: "Select Cargo Available Status"
    },
    departureDate: {
      name: "departureDate",
      label: "Departure Date"
    },
    arrivalDate: {
      name: "arrivalDate",
      label: "Arrival Date"
    },
    importFee: {
      name: "import_fee",
      label: "Import Fee"
    },
    handlingFee: {
      name: "handling_fee",
      label: "Handling Fee"
    },
    duty: {
      name: "duty",
      label: "Duty ($)"
    },
    images: {
      name: "images",
      label: "Add Pictures"
    }
  }
}

const {
  formField: {
    arrivalDate,
    departureDate,
    images,
    status,
    title,
    trackingId,
    type,
    cargoAvailableStatus
  }
} = Model

export const initialValues = {
  [title.name]: "",
  [type.name]: "",
  [status.name]: "",
  [cargoAvailableStatus.name]: "",
  [trackingId.name]: "",
  [departureDate.name]: new Date(),
  // [departureDate.name]: "",
  [arrivalDate.name]: (() => {
    // Set arrival date as current date plus 3 days
    const currentDate = new Date()
    const arrivalDate = new Date(currentDate)
    arrivalDate.setDate(arrivalDate.getDate() + 3)
    return arrivalDate
  })(),
  // [arrivalDate.name]: "",
  [images.name]: []
}

export const validationSchema = Yup.object({
  [title.name]: Yup.string().required("Title is required"),
  [type.name]: Yup.string().required("Type is required"),
  [status.name]: Yup.string().required("Status is required"),
  [cargoAvailableStatus.name]: Yup.string().required(
    "Cargo available status is required"
  ),
  [trackingId.name]: Yup.string()
    .matches(/^\d+$/, "Tracking id must be a number")
    .required("Tracking id is required"),
  [departureDate.name]: Yup.date(),
  [arrivalDate.name]: Yup.date().min(
    Yup.ref(departureDate.name),
    "Departure date must be greater than Arrival date"
  ),
  [images.name]: Yup.array()
})
