import React from "react"

const LeftArrow = ({ disabled, onClick }) => {
  return (
    <li
      onClick={onClick}
      className={`p-2 h-[32px] border-2 border-gray-200 text-center flex box-border items-center rounded-md font-semibold text-[0.9rem] min-w-[32px] cursor-pointer  hover:bg-gray-500 hover:text-white ${
        disabled && "pointer-events-none opacity-[0.2]"
      }`}
    >
      Prev
    </li>
  )
}

export default LeftArrow
