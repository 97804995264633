import React from "react"
import Dialog from "./Dialog"
import { BackDropLoader } from "../loader"

const OrderPlaced = ({ open, handleClose, onSubmitForm, isLoading }) => {
  return (
    <Dialog small={true} isOpen={open} handleClose={handleClose}>
      {isLoading && <BackDropLoader />}
      <div className="flex h-full w-full flex-col justify-center gap-5">
        <p className="font-semibold text-center">Are you sure</p>
        <div className=" flex flex-col gap-1">
          <button
            onClick={onSubmitForm}
            className="bg-orange-400 hover:bg-orange-500 font-semibold text-white py-1 rounded-md"
          >
            Order Placed
          </button>
          <button
            className="bg-gray-200 py-1 rounded-md font-semibold hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default OrderPlaced
