import { RiProfileLine } from "react-icons/ri"
import { TbPackage } from "react-icons/tb"
import { LiaUserTieSolid } from "react-icons/lia"
import { GrSchedules } from "react-icons/gr"
import { FaRegUser } from "react-icons/fa6"
import { HiOutlineDocumentReport } from "react-icons/hi"
const style = { fontSize: "1.3em" }
const closedMenustyle = { fontSize: "1.3em" }

export const menuData = [
  {
    id: 1,
    title: "Dashboard",
    icon: <RiProfileLine style={style} className="listIcon" />,
    to: "/dashboard"
    // iconStyle:style,
    // closedMenustyleiconStyle:closedMenustyle,
  },
  {
    id: 2,
    title: "Work Orders",
    icon: <GrSchedules style={style} className="listIcon" />,
    to: "/dashboard/work-orders"
  },
  {
    id: 3,
    title: "Packages",
    icon: <TbPackage style={style} className="listIcon" />,
    to: "/dashboard/packages"
  },
  {
    id: 4,
    title: "Customers",
    icon: <FaRegUser style={style} className="listIcon" />,
    to: "/dashboard/customers"
  }
  // {
  //   id: 5,
  //   title: "Reports",
  //   icon: <HiOutlineDocumentReport style={style} className="listIcon" />,
  //   to: "/dashboard/reports"
  // }
]
