import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const RequireAuth = () => {
  // console.log(token)
  const { role } = useAuth()

  if (!localStorage.getItem("admin")) {
    return <Navigate to={"/login"} replace />
  } else {
    if (role === "admin") {
      return <Outlet />
    } else {
      localStorage.clear()
      return (
        <Navigate
          to={"/login"}
          replace
          state={{
            customer: true
          }}
        />
      )
    }
  }
}

export default RequireAuth
