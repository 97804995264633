import { jwtDecode } from "jwt-decode"

const useAuth = () => {
  const token = JSON.parse(localStorage.getItem("admin"))
  if (token) {
    const decode = jwtDecode(token.accessToken)
    const { email, role } = decode.userInfo
    return { email, role }
  }
  return { username: "", role: "" }
}

export default useAuth
