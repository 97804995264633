import React from "react"
import { Loader } from "../loader"

const TableLoader = () => {
  return (
    <tbody className="flex flex-col h-[100%] overflow-y-auto">
      <tr className=" ">
        <td className="flex border-2  items-center justify-center p-32">
          <Loader />
        </td>
      </tr>
    </tbody>
  )
}

export default TableLoader
