import React from "react"

const Card = ({ title, value, color }) => {
  // console.log({ value })
  return (
    <div
      className={`h-full flex flex-row gap-8 bg-blue-200 p-4 rounded-md ${
        color === "gray" && "bg-gray-100"
      }`}
    >
      <div className="font-medium">{title}</div>
      <div className="text-2xl font-semibold">{value}</div>
    </div>
  )
}

export default Card
