import React from "react"
import Dialog from "./Dialog"
import { BackDropLoader } from "../loader"
import { DateFormat } from "../../../utils/format"

const DeleteByDate = ({ date, open, handleClose, onSubmitForm, isLoading }) => {
  return (
    <Dialog small={true} isOpen={open} handleClose={handleClose}>
      {isLoading && <BackDropLoader />}
      <div className="flex h-full w-full flex-col justify-center gap-3">
        <div className="px-6">
          <p className="font-semibold text-center">
            Are you sure you want to delete Work orders equals and older than{" "}
            <span>{DateFormat(date)}</span>
          </p>
        </div>
        <div className=" flex flex-col gap-1">
          <button
            onClick={onSubmitForm}
            className="font-semibold bg-red-400 text-white py-1 rounded-md hover:bg-red-500"
          >
            Delete
          </button>
          <button
            className="font-semibold bg-gray-200 py-1 rounded-md hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteByDate
