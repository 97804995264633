import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetWorkorderByIdQuery,
  useGetWorkordersQuery,
} from "../../../../app/services/workOrderSlice";
import { BackDropLoader } from "../../../common/loader";
import { IoMdArrowBack, IoMdRefresh } from "react-icons/io";
import { DateFormat, calculateTotalWeight } from "../../../../utils/format";
import WOPackageTable from "./WOPackageTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFileInvoice } from "react-icons/fa6";
import { Dialog } from "../../../common/Dialog";
import { useDialogModel } from "../../../../hooks";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import GenerateInvoice from "./GenerateInvoice";

const ViewWorkOrder = () => {
  const { id } = useParams();
  const [open, openDialog, closeDialog] = useDialogModel();

  const { state } = useLocation();
  window.history.replaceState({}, document.title);
  const { data, isLoading, error, isFetching, isSuccess, refetch } =
    useGetWorkorderByIdQuery(id, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const navigate = useNavigate();

  const onHandleGenerateInvoice = () => {
    openDialog();
  };

  useEffect(() => {
    if (state?.success) {
      // console.log("creating work order")
      toast.success(state.message);
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({});
        navigate("/login", { state: { expired: true } });
      }
    }
  }, [error, navigate]);

  if (isLoading || isFetching) {
    return (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4">
        {(isLoading || isFetching) && <BackDropLoader />}

        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
      </div>
    );
  }
  if (isSuccess) {
    return data ? (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 ">
        <Dialog isOpen={open} handleClose={closeDialog}>
          <GenerateInvoice data={data} />
        </Dialog>
        <ToastContainer autoClose={1000} />
        {/* {isLoadingUpdateMt && <BackDropLoader />} */}
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        <div className="p-4 flex flex-col gap-4 ">
          <div className="flex flex-row  items-center justify-between ">
            <div className="flex gap-2 flex-row items-center">
              <h3 className="font-semibold text-2xl">View Work Order</h3>
              <div
                onClick={() => {
                  navigate(`/dashboard/work-orders/edit/${id}`);
                }}
                className="flex items-center gap-1 text-blue-500 hover:border-b-2 cursor-pointer border-blue-600"
              >
                Edit
              </div>
            </div>
            <div className=" flex flex-row gap-3">
              <button
                onClick={onHandleGenerateInvoice}
                className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white  bg-blue-500 hover:bg-blue-600`}
              >
                <FaFileInvoice size={18} />
                Generate Invoice
              </button>
              <button
                onClick={() => refetch()}
                className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2  bg-gray-200 hover:bg-gray-300`}
              >
                <IoMdRefresh size={20} />
                Refresh
              </button>
            </div>
          </div>
          <div className="flex flex-row gap-8">
            <div className="border-2 w-[50%] flex flex-col capitalize font-semibold">
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Type</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.type}
                </div>
              </div>

              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Title</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.title}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Tracking Id
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.trackingId}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Cargo Available Status
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1 flex">
                  {data?.cargoAvailableStatus === "inProcessOfCheckIn" ? (
                    <p className="bg-slate-200 text-slate-700 rounded-md px-2">
                      In Process Of Check In
                    </p>
                  ) : data?.cargoAvailableStatus === "receivedAtWarehouse" ? (
                    <p className=" text-blue-700 bg-blue-200 rounded-md px-2">
                      Received At Warehouse
                    </p>
                  ) : (
                    <p className=" text-blue-700 bg-blue-200 rounded-md px-2">
                      Ready for Pick up
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Status</div>
                <div className="border-2 basis-[50%] ps-2 py-1 flex">
                  {data?.status === "placed" ? (
                    <p className="bg-orange-200 text-orange-700 px-2 rounded-md">
                      Placed
                    </p>
                  ) : data?.status === "shipped" ? (
                    <p className="bg-emerald-200 text-emerald-700 px-2 rounded-md">
                      Shipped
                    </p>
                  ) : (
                    <p className="bg-blue-200 text-blue-700 px-2 rounded-md">
                      Delivered
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Arrival Date
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {DateFormat(data?.arrivalDate, true)}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Departure Date
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {DateFormat(data?.departureDate, true)}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Weight</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.total_weight > 0
                    ? `${data?.total_weight} lbs`
                    : "0 lbs"}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Cost Calculator Used
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.want_to_use_calculator ? "Yes" : "No"}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Import Fee</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {`${data?.import_fee}  ${data?.import_fee ? "USD" : "0 USD"}`}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Handling Fee
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {`${data?.handling_fee}  ${
                    data?.handling_fee ? "USD" : "0 USD"
                  }`}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Duty</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {`${data?.duty} ${data?.duty ? "USD" : "0 USD"}`}
                </div>
              </div>
              {data?.miscellaneous_fees_one && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Miscellaneous Fees 1
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                    {data?.miscellaneous_fees_one ? (
                      <>
                        <div>{`${data?.miscellaneous_fees_one} USD`} </div>
                        <div className="bg-blue-50 m-2 rounded-sm p-2">
                          {`${data?.miscellaneous_fees_one_note}`}{" "}
                        </div>
                      </>
                    ) : (
                      "0 USD"
                    )}
                  </div>
                </div>
              )}
              {data?.miscellaneous_fees_two && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Miscellaneous Fees 2
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                    {data?.miscellaneous_fees_two ? (
                      <>
                        <div>{`${data?.miscellaneous_fees_two} USD`} </div>
                        <div className="bg-blue-50 m-2 rounded-sm p-2">
                          {`${data?.miscellaneous_fees_two_note}`}{" "}
                        </div>
                      </>
                    ) : (
                      "0 USD"
                    )}
                  </div>
                </div>
              )}
              {data?.miscellaneous_fees_three && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Miscellaneous Fees 3
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                    {data?.miscellaneous_fees_three ? (
                      <>
                        <div>{`${data?.miscellaneous_fees_three} USD`} </div>
                        <div className="bg-blue-50 m-2 rounded-sm p-2">
                          {`${data?.miscellaneous_fees_three_note}`}{" "}
                        </div>
                      </>
                    ) : (
                      "0 USD"
                    )}
                  </div>
                </div>
              )}

              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Total Cost</div>
                <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                  {data?.total_cost ? (
                    <div>{`${data?.total_cost} USD`} </div>
                  ) : (
                    "0 USD"
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h2 className="flex text-xl font-semibold">
                Work Order Pictures
              </h2>
              <div className="flex flex-row gap-4">
                {data?.pics_url?.length > 0 ? (
                  data?.pics_url.map((file, index) => {
                    return (
                      <div className="flex h-32 w-32" key={index}>
                        <img
                          className="w-full  h-full rounded-md object-cover"
                          src={`${process.env.REACT_APP_URL}/uploads/images/${file}`}
                          alt="pic"
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    onClick={() => {
                      navigate(`/dashboard/work-orders/edit/${id}`);
                    }}
                    className="flex items-center gap-1 text-blue-500 hover:border-b-2 cursor-pointer border-blue-600"
                  >
                    Add Pictures
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <WOPackageTable
            refetch={refetch}
            workorder={data}
            id={id}
            data={data?.packages}
            type={data?.type}
          />
        </div>
      </div>
    ) : (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 h-96">
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        No work Order found
      </div>
    );
  }
  if (!isSuccess && !isLoading) {
    return (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 h-96">
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        No work Order found
      </div>
    );
  }
};

export default ViewWorkOrder;
