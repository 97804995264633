import * as Yup from "yup"

export const Model = {
  formField: {
    email: {
      name: "email",
      label: "Username or Email"
    },
    password: {
      name: "password",
      label: "Password"
    }
  }
}

const {
  formField: { email, password }
} = Model

export const initialValues = {
  [email.name]: "",
  [password.name]: ""
}

export const validationSchema = Yup.object({
  [email.name]: Yup.string().required("Email is required"),
  [password.name]: Yup.string().required("Password is required")
})
