import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dialog } from "../../components/common/Dialog";
import { useDialogModel } from "../../hooks";
import { FaFileInvoice } from "react-icons/fa6";
import { useLazyGetMonthlyInvoiceQuery } from "../../app/services/statsSlice";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../assets/images/rbmlogo.png";
import { BackDropLoader } from "../../components/common/loader";
import { MdOutlineFileDownload } from "react-icons/md";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [open, openDialog, closeDialog] = useDialogModel();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [triggerInvoiceQuery, invoiceQueryResult] =
    useLazyGetMonthlyInvoiceQuery();
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };
  const onHandleGenerateInvoiceModel = () => {
    openDialog();
  };
  const onHandleGenerateMonthlyInvoice = async () => {
    // console.log({ selectedDate });
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    // console.log({ month, year });
    try {
      const result = await triggerInvoiceQuery({ month, year }).unwrap();
      //   console.log({ result });
      if (result.success) {
        setInvoiceData(result?.result);
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          containerId: "parentToast",
          closeOnClick: true,
          toastId: 12,
          position: "bottom-center",
          autoClose: 1000,
        }
      );
    }
    // openDialog();
  };
  //   console.log({ invoiceData });
  const onHandleDownloadInvoice = () => {
    console.log("Donwload invoice");
    // openDialog();
    html2canvas(document.querySelector("#monthInvoiceCapture")).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png", 1.0);
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: [612, 792],
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`INVOICE-${invoiceData?.month}.pdf`);
      }
    );
  };

  return (
    <div className="mt-4">
      <Dialog
        isOpen={open}
        handleClose={() => {
          closeDialog();
          setInvoiceData(null);
        }}
      >
        <ToastContainer containerId={"parentToast"} />
        <div className=" w-[90%] m-auto mt-12 flex flex-col gap-6 items-end">
          <div className="flex flex-row gap-3 justify-end items-center">
            <ReactDatePicker
              className="border-2 bg-blue-100 border-blue-100  py-[0.1em] px-[0.4em] rounded-md  "
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)} // Update selected date
              renderMonthContent={renderMonthContent}
              showMonthYearPicker
              dateFormat="MM/yyyy"
            />
            <button
              onClick={onHandleGenerateMonthlyInvoice}
              className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white  bg-blue-500 hover:bg-blue-600`}
            >
              <FaFileInvoice size={18} />
              Generate
            </button>
          </div>
          {invoiceQueryResult.isLoading || invoiceQueryResult.isFetching ? (
            <BackDropLoader />
          ) : (
            invoiceQueryResult.isSuccess &&
            invoiceData && (
              <>
                <div
                  id="monthInvoiceCapture"
                  className="flex flex-col gap-6 w-[100%] border-2 border-gray-200 py-8 px-12 items-end"
                >
                  <div>
                    <img className="w-[4em]" src={Logo} alt="rbmlogo" />
                  </div>
                  <div className="text-[1rem] font-bold  tracking-wider ">
                    {" "}
                    <p>{`Invoice Month : ${invoiceData?.month}`} </p>{" "}
                  </div>

                  <div className="flex flex-col gap-1 w-[40%] font-semibold">
                    <hr className="my-3 h-[0.09em]  bg-slate-600   " />
                    <div className="flex flex-row justify-between ">
                      <p>Total Workorders</p>
                      <p>{invoiceData?.totalWorkOrders}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p>Total Cost</p>
                      <p>{`${invoiceData?.totalCost} USD`}</p>
                    </div>
                  </div>
                </div>
                <button
                  onClick={onHandleDownloadInvoice}
                  className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white  bg-blue-500 hover:bg-blue-600`}
                >
                  <MdOutlineFileDownload size={18} />
                  Download Invoice
                </button>
              </>
            )
          )}
        </div>
      </Dialog>
      <button
        onClick={onHandleGenerateInvoiceModel}
        className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white  bg-blue-500 hover:bg-blue-600`}
      >
        <FaFileInvoice size={18} />
        Generate Monthly Invoice
      </button>
    </div>
  );
};

export default Invoice;
