import React from "react"
import { MdOutlineModeEditOutline } from "react-icons/md"
import { IoMdEye } from "react-icons/io"
import { AiOutlineDelete } from "react-icons/ai"
const TableButton = ({ type, ...props }) => {
  return (
    <button
      className={` bg-blue-400 font-bold p-[0.16em] transition-all duration-[0.3s]  rounded-full 

          ${type === "edit" && "bg-blue-300 text-white hover:bg-blue-500"}
          ${
            type === "view" &&
            "bg-gray-500 text-white hover:bg-gray-600 hover:text-gray-300"
          }
          ${type === "delete" && "bg-red-500 text-white hover:bg-red-600"}
          `}
      {...props}
    >
      {type === "edit" && <MdOutlineModeEditOutline size={18} />}
      {type === "view" && <IoMdEye size={19} />}
      {type === "delete" && <AiOutlineDelete size={19} />}
    </button>
  )
}

export default TableButton
