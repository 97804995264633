import React from "react"
import Dialog from "./Dialog"
import { BackDropLoader } from "../loader"

const InProccessOfCheckIn = ({
  open,
  handleClose,
  onSubmitForm,
  isLoading
}) => {
  return (
    <Dialog small={true} isOpen={open} handleClose={handleClose}>
      {isLoading && <BackDropLoader />}
      <div className="flex h-full w-full flex-col justify-center gap-5">
        <p className="font-semibold text-center">Are you sure</p>
        <div className=" flex flex-col gap-1">
          <button
            onClick={onSubmitForm}
            className="font-semibold bg-slate-400 hover:bg-slate-500 text-white py-1 rounded-md"
          >
            In Process Of Check In
          </button>
          <button
            className="font-semibold bg-gray-200 py-1 rounded-md hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default InProccessOfCheckIn
