import React from "react";
// import { CircularIconButton } from "../formElements/buttons"
// import { TextField } from "../formElements/oecDashboard/table"
import { IconTextButton } from "../formElements";

const TableTopHeader = ({
  refreshBtn = false,
  addBtn = false,
  addBtnFee = false,
  addBtnFav = false,
  removeBtnFav = false,
  selectedRow,
  getSortedData,
  addBtnTitle,
  addBtnFeeTitle,
  addBtnFavTitle,
  removeBtnFavTitle,
  onRefreshClick,
  onAddClick,
  onAddFeeClick,
  onToggleFav,
  setQuery,
  searchTitle,
}) => {
  // console.log({ selectedRow, getSortedData });
  const itemSelected = getSortedData?.filter(
    (item) => item?._id === selectedRow
  )[0];
  // console.log({ itemSelected });
  return (
    <div className="flex items-center py-2 px-4 justify-between">
      <div className="relative w-64">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-2 ps-10 text-sm text-gray-900 font-semibold border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 outline-none border-2"
          placeholder={searchTitle ? searchTitle : "Search"}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        {refreshBtn && (
          <IconTextButton
            type={"refresh"}
            title={"Refresh"}
            onClick={onRefreshClick}
          />
        )}

        {itemSelected && itemSelected.favourite && removeBtnFav && (
          <IconTextButton
            type={"favremove"}
            title={removeBtnFavTitle}
            onClick={onToggleFav}
          />
        )}
        {itemSelected && !itemSelected.favourite && addBtnFav && (
          <IconTextButton
            type={"fav"}
            title={addBtnFavTitle}
            onClick={onToggleFav}
          />
        )}
        {addBtnFee && (
          <IconTextButton
            type={"fee"}
            title={addBtnFeeTitle}
            onClick={onAddFeeClick}
          />
        )}
        {addBtn && (
          <IconTextButton
            type={"add"}
            title={addBtnTitle ? addBtnTitle : "Add"}
            onClick={onAddClick}
          />
        )}
        {/* {true && <Button type={"add"} title={"Add"} onClick={onAddClick} />} */}
      </div>
    </div>
  );
};

export default TableTopHeader;
