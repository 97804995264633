import React from "react"
import { IoMdLogOut } from "react-icons/io"
import LogoSection from "./LogoSection"
import MenuList from "./MenuList"
import { useSideMenuContext } from "../../../context/SideMenuContext"
import { useNavigate } from "react-router-dom"
const Sidebar = ({ navigateToPage }) => {
  const { isMenuOpen } = useSideMenuContext()
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.clear()
    navigate("/login")
  }
  return (
    <div className="gap-0 w-[100%] h-[100%] flex flex-col">
      {/* logo container */}
      <LogoSection />
      <MenuList navigateToPage={navigateToPage} />
      <div className="mb-4 mx-2 mt-auto flex justify-center">
        {isMenuOpen ? (
          <button
            onClick={handleLogout}
            className="w-full border-2 border-gray-500 bg-[#659aec] hover:bg-[#4372bb] text-white hover:text-white rounded-lg px-3 py-2 font-semibold "
          >
            Logout
          </button>
        ) : (
          <button
            onClick={handleLogout}
            className="bg-[#659aec] hover:bg-[#4372bb] text-white hover:text-white p-3 rounded-full"
          >
            <IoMdLogOut size={20} />
          </button>
        )}
      </div>
    </div>
  )
}

export default Sidebar
