import { apiSlice } from "./apiSlice"

const urlPath = "/auth"

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendLogin: builder.mutation({
      query: (credentials) => ({
        url: `${urlPath}/login`,
        method: "POST",
        body: {
          ...credentials
        }
      })
    })
  })
})

export const {useSendLoginMutation} = authApiSlice
