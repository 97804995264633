import React from "react"
import { useField } from "formik"
import Label from "./Label"
import "./index.css"

const Checkbox = ({ desc, fieldlabel, ...props }) => {
  const [field, _] = useField(props)
  // console.log({ field })
  return (
    <div className="flex flex-col items-start gap-[0.1em] w-[100%]">
      <Label label={fieldlabel} />
      <div className="flex flex-row w-[100%] items-center gap-3 bg-gray-50 relative">
        <input
          {...field}
          {...props}
          checked={field.value}
          className="text-blue-500 focus:ring-blue-100 border-gray-300 peer rounded-lg w-4 h-4 absolute left-2"
          id={props.id || props.name}
          type="checkbox"
        />
        <label
          className="w-[100%] h-[40px] cursor-pointer flex flex-row justify-between items-center border-2 rounded-sm pl-9
          active:bg-blue-200 
          peer-checked:bg-blue-100
          hover:bg-blue-100
          peer-checked:border-blue-300
          peer-focus:outline-none
          
          "
          htmlFor={props.id || props.name}
        >
          <div className="flex flex-row justify-between items-center">
            <p className="text-sm font-medium text-gray-900">{desc}</p>
          </div>
        </label>
      </div>
    </div>
  )
}

export default Checkbox
