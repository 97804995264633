import _ from "lodash";
import TableEmptyRow from "./TableEmptyRow";
import TableLoader from "./TableLoader";
import TableRow from "./TableRow";
// import { TableBody as TableBodyWrapper } from "@/styles/oecDashboard"
// import TableRow from "./TableRow"

const TableBody = ({
  onEditItem,
  onDeleteItem,
  onViewItem,
  showEditButton = false,
  showDeleteButton = false,
  showViewButton = false,
  isLoading,
  currentTableData,
  columns,
  pageStartCount,
  selectedRows,
  handleRowSelect,
  hover = true,
  highlight = false,
}) => {
  // eslint-disable-next-line no-constant-condition
  if (isLoading) {
    return <TableLoader columns={columns} />;
  }
  if (currentTableData?.length === 0) {
    return <TableEmptyRow />;
  }

  return (
    <tbody className="flex flex-col h-[100%] overflow-y-auto mt-4 ">
      {currentTableData?.map((item, index) => {
        const isSelected = selectedRows?.includes(item?._id);
        return (
          <TableRow
            highlight={highlight}
            hover={hover}
            onDeleteItem={onDeleteItem}
            onViewItem={onViewItem}
            showEditButton={showEditButton}
            showDeleteButton={showDeleteButton}
            showViewButton={showViewButton}
            onEditItem={onEditItem}
            data={item}
            key={index}
            itemNumber={pageStartCount + index}
            columns={columns}
            isSelected={isSelected}
            handleRowSelect={handleRowSelect}
          />
        );
      })}
    </tbody>
  );
};

export default TableBody;
