export const columns = [
  {
    title: "Title",
    styles: "basis-[7%]",
    sorting: false,
    key: "title"
  },
  {
    title: "Type",
    styles: "basis-[7%]",
    sorting: false,
    key: "type"
  },
  {
    title: "Tracking ID",
    styles: "basis-[9%]",
    sorting: false,
    key: "trackingId"
  },
  {
    title: "Weight (lbs)",
    styles: "basis-[8%]",
    sorting: false,
    key: "packages",
    weight: true
  },
  {
    title: "Status",
    styles: "basis-[6%]",
    sorting: false,
    key: "status"
  },
  {
    title: "Cargo Available Status",
    styles: "basis-[13%]",
    sorting: false,
    key: "cargoAvailableStatus"
  },
  // {
  //   title: "Weight Calculator",
  //   styles: "basis-[9%]",
  //   sorting: false,
  //   key: "want_to_use_calculator",
  //   bool_value: true
  // },
  {
    title: "Departure Date",
    styles: "basis-[10%]",
    sorting: true,
    key: "departureDate",
    date: true
  },
  {
    title: "Arrival Date",
    styles: "basis-[10%]",
    sorting: true,
    key: "arrivalDate",
    date: true
  },
  {
    title: "Action(s)",
    styles: "basis-[7%]",
    sorting: false,
    key: null,
    name: "action"
  }
]
