import React, { useEffect, useState } from "react"
import { InputFieldWithLabel } from "../../../../common/formElements"
import { Model } from "../../../../../formModel/package"
import { useFormikContext } from "formik"

const PackageWeightAndDimensions = () => {
  const { setFieldValue, values } = useFormikContext()
  const {
    formField: { length, weight, width, height }
  } = Model
  useEffect(() => {
    let toalWeight
    if (values.type === "box") {
      if (values.length && values.width && values.height) {
        toalWeight = (
          (Number(values.length) *
            Number(values.width) *
            Number(values.height)) /
          166
        ).toFixed(2)
        setFieldValue(weight.name, toalWeight)
      } else {
        setFieldValue(weight.name, "")
      }
    }
    if (values.type === "other") {
      setFieldValue(weight.name, "")
    }
  }, [
    setFieldValue,
    values.height,
    values.length,
    values.type,
    values.width,
    weight.name
  ])
  return (
    <>
      {values.type === "box" && (
        <div className="flex flex-row gap-3">
          <InputFieldWithLabel
            fieldlabel={length.label}
            placeholder={length.placeholder}
            name={length.name}
            onChange={(event) =>
              setFieldValue(
                length.name,
                event.target.value.replace(/[^0-9.]/g, "")
              )
            }
          />
          <InputFieldWithLabel
            fieldlabel={width.label}
            placeholder={width.placeholder}
            name={width.name}
            onChange={(event) =>
              setFieldValue(
                width.name,
                event.target.value.replace(/[^0-9.]/g, "")
              )
            }
          />
        </div>
      )}
      <div className="flex flex-row gap-3">
        {values.type === "box" && (
          <InputFieldWithLabel
            fieldlabel={height.label}
            placeholder={height.placeholder}
            name={height.name}
            onChange={(event) =>
              setFieldValue(
                height.name,
                event.target.value.replace(/[^0-9.]/g, "")
              )
            }
          />
        )}
        <InputFieldWithLabel
          disabled={values.type === "box"}
          fieldlabel={weight.label}
          placeholder={values.type === "box" ? "" : weight.placeholder}
          name={weight.name}
          onChange={(event) =>
            setFieldValue(
              weight.name,
              event.target.value.replace(/[^0-9.]/g, "")
            )
          }
        />
      </div>
    </>
  )
}

export default PackageWeightAndDimensions
