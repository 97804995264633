import { Navigate, Route, Routes } from "react-router-dom"
import Login from "./pages/auth/Login"
import {
  DashboardHome,
  DashboardLayout,
  Package,
  Customer,
  WorkOrder
} from "./pages/dashboard"
import CreateWorkOrder from "./components/dashboard/workOrder/create/CreateWorkOrder"
import EditWorkOrder from "./components/dashboard/workOrder/edit/EditWorkOrder"
import ViewWorkOrder from "./components/dashboard/workOrder/view/ViewWorkOrder"
import AuthRoutes from "./components/auth/AuthRoutes"
import RequireAuth from "./components/auth/RequireAuth"

const NavigateToLogin = () => {
  return <Navigate to={"/login"} replace />
}



function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<NavigateToLogin />} />
        <Route element={<AuthRoutes />}>
          <Route path="login" element={<Login />} />

          <Route
            path="*"
            element={
              <AuthRoutes redirect="/login">
                <Login />
              </AuthRoutes>
            }
          />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardHome />} />
            <Route path="work-orders">
              <Route index element={<WorkOrder />} />
              <Route path="new" element={<CreateWorkOrder />} />
              <Route path="view/:id" element={<ViewWorkOrder />} />
              <Route path="edit/:id" element={<EditWorkOrder />} />
            </Route>
            {/* <Route element={<WorkOrder />} /> */}
            <Route path="packages" element={<Package />} />
            <Route path="customers" element={<Customer />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default App
