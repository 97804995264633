import React from "react"

const ErrorMessage = ({ error, touched }) => {
  // console.log({ error, touched })
  let content
  if (error && touched) {
    content = <p className="text-red-400 text-sm font-semibold">{error}</p>
  }
  return content
}

export default ErrorMessage
