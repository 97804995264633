import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const urlPath = "/workorders";

const slice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkorders: builder.query({
      query: ({
        limit,
        page,
        sort,
        type,
        status,
        cargoAvailableStatus,
        debouncedSearchTerm,
        searchField,
      }) => ({
        url: (() => {
          let queryString = `${urlPath}?limit=${limit}&page=${page}&sort=${sort}`;
          if (type) {
            queryString += `&type[in]=${type}`;
          }
          if (status) {
            queryString += `&status=${status}`;
          }
          if (cargoAvailableStatus) {
            queryString += `&cargoAvailableStatus=${cargoAvailableStatus}`;
          }
          if (debouncedSearchTerm) {
            queryString += `&search=${debouncedSearchTerm}&searchField=${searchField}`;
          }
          return queryString;
        })(),
      }),
      keepUnusedDataFor: 30,
      transformResponse: (res) => {
        return res.result;
      },
      providesTags: (result, error, arg) => {
        if (result?.data) {
          const tags = [
            { type: "workorder", id: "LIST" },
            ...result.data.map(({ _id }) => ({ type: "workorder", id: _id })),
          ];
          return tags;
        } else {
          return [{ type: "workorder", id: "LIST" }];
        }
      },
    }),
    getWorkorderById: builder.query({
      query: (id) => ({
        url: `${urlPath}/${id}/packages`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => {
        return res.result;
      },
      providesTags: (result, error, id) => {
        return [{ type: "workorder", id }];
      },
    }),
    addWorkOrder: builder.mutation({
      query: ({
        title,
        type,
        status,
        trackingId,
        cargoAvailableStatus,
        departureDate,
        arrivalDate,
        images,
      }) => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("type", type);
        formData.append("trackingId", trackingId);
        formData.append("status", status);
        formData.append("cargoAvailableStatus", cargoAvailableStatus);
        formData.append("departureDate", departureDate);
        formData.append("arrivalDate", arrivalDate);
        images.forEach((element) => formData.append("workorderimg", element));
        return {
          url: urlPath,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: "LIST" }];
      },
    }),
    addWorkOrderPackage: builder.mutation({
      query: ({
        id,
        title,
        length,
        width,
        height,
        weight,
        customCostPerPound,
        wantToUseCalculator,
        cost,
        type,
        package_content,
        images,
        location,
      }) => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("length", length);
        formData.append("width", width);
        formData.append("height", height);
        formData.append("cost", cost);
        formData.append("type", type);
        formData.append("weight", weight);
        formData.append("location", location);
        formData.append("want_to_use_calculator", wantToUseCalculator);
        formData.append("custom_cost_per_pound", customCostPerPound);
        formData.append("package_content", package_content);
        images.forEach((element) => formData.append("packageimg", element));
        return {
          url: `/workorders/${id}/packages`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg?.id }];
      },
    }),
    updateWorkOrder: builder.mutation({
      query: ({
        id,
        title,
        type,
        status,
        trackingId,
        cargoAvailableStatus,
        departureDate,
        arrivalDate,
        images,
      }) => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("type", type);
        formData.append("trackingId", trackingId);
        formData.append("status", status);
        formData.append("cargoAvailableStatus", cargoAvailableStatus);
        formData.append("departureDate", departureDate);
        formData.append("arrivalDate", arrivalDate);
        // formData.append("images", images)
        images
          .filter((item) => typeof item === "string")
          .forEach((element, index) =>
            formData.append(`imgurl${index + 1}`, element)
          );
        images
          .filter((item) => !(typeof item === "string"))
          .forEach((element) => formData.append("workorderimg", element));
        return {
          url: `${urlPath}/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    updateWorkOrderFee: builder.mutation({
      query: ({
        id,
        duty,
        handling_fee,
        import_fee,
        wantToUseCalculator,
        miscellaneous_fees_one,
        miscellaneous_fees_one_note,
        miscellaneous_fees_two,
        miscellaneous_fees_two_note,
        miscellaneous_fees_three,
        miscellaneous_fees_three_note,
        mscFeeCounter,
        total_weight,
        total_cost,
      }) => {
        return {
          url: `${urlPath}/${id}/updatefee`,
          method: "PUT",
          body: {
            want_to_use_calculator: wantToUseCalculator,
            duty,
            handling_fee,
            import_fee,
            total_weight,
            total_cost,
            miscellaneous_fees_one:
              mscFeeCounter > 0 ? miscellaneous_fees_one : "",
            miscellaneous_fees_one_note:
              mscFeeCounter > 0 ? miscellaneous_fees_one_note : "",
            miscellaneous_fees_two:
              mscFeeCounter > 1 ? miscellaneous_fees_two : "",
            miscellaneous_fees_two_note:
              mscFeeCounter > 1 ? miscellaneous_fees_two_note : "",
            miscellaneous_fees_three:
              mscFeeCounter > 2 ? miscellaneous_fees_three : "",
            miscellaneous_fees_three_note:
              mscFeeCounter > 2 ? miscellaneous_fees_three_note : "",
          },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    updateWorkOrderPackage: builder.mutation({
      query: ({
        id,
        pId,
        title,
        length,
        width,
        height,
        cost,
        location,
        weight,
        customCostPerPound,
        wantToUseCalculator,
        type,
        images,
      }) => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("length", length);
        formData.append("width", width);
        formData.append("height", height);
        formData.append("cost", cost);
        formData.append("type", type);
        formData.append("weight", weight);
        formData.append("location", location);

        formData.append("want_to_use_calculator", wantToUseCalculator);
        formData.append("custom_cost_per_pound", customCostPerPound);
        // formData.append("images", images)
        images
          .filter((item) => typeof item === "string")
          .forEach((element, index) =>
            formData.append(`imgurl${index + 1}`, element)
          );
        images
          .filter((item) => !(typeof item === "string"))
          .forEach((element) => formData.append("packageimg", element));
        return {
          url: `/workorders/${id}/packages/${pId}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    updateWorkOrderPackageFav: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/workorders/favorites/packages`,
          method: "PUT",
          body: { id },
        };
      },
      invalidatesTags: (result, error, arg) => {
        console.log({ arg });
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    updateBulkWorkOrder: builder.mutation({
      query: ({
        ids,
        status,
        cargoAvailableStatus,
        departureDate,
        arrivalDate,
      }) => {
        return {
          url: `${urlPath}`,
          method: "PUT",
          body: {
            ids,
            cargoAvailableStatus,
            status,
            departureDate,
            arrivalDate,
            // active: data.active
          },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    deleteWorkOrder: builder.mutation({
      query: ({ id }) => ({
        url: `${urlPath}/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && result.success;
        },
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    deleteWorkOrderPackage: builder.mutation({
      query: ({ id, pId }) => ({
        url: `/workorders/${id}/packages/${pId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && result.success;
        },
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
    deleteThreeMonthsOlderWorkOrderPackage: builder.mutation({
      query: ({ date }) => ({
        url: `${urlPath}`,
        validateStatus: (response, result) => {
          return response.status === 200 && result.success;
        },
        method: "DELETE",
        body: {
          date,
        },
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) return [];
        return [{ type: "workorder", id: arg.id }];
      },
    }),
  }),
});

export const {
  useGetWorkordersQuery,
  useAddWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useDeleteWorkOrderMutation,
  useDeleteThreeMonthsOlderWorkOrderPackageMutation,
  useGetWorkorderByIdQuery,
  useUpdateBulkWorkOrderMutation,
  useAddWorkOrderPackageMutation,
  useDeleteWorkOrderPackageMutation,
  useUpdateWorkOrderPackageMutation,
  useUpdateWorkOrderFeeMutation,
  useUpdateWorkOrderPackageFavMutation,
} = slice;
